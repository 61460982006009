import { gql } from "@apollo/client";
import { useEffect } from "react";

import useData from "./useData";
import useGotoUrl from "./useGotoUrl";

const DEFAULT_REDIRECT_PATH = "/readmin";

export default function useEnforceAuth({ enforceUnauthenticated = false, enforceAuthenticated = false } = {}) {
  const [data, dataMeta] = useData(
    gql`
      query useEnforceAuth {
        currentUser {
          id
        }
      }
    `,
    null,
    {
      skip: !enforceUnauthenticated && !enforceAuthenticated,
    },
  );

  const gotoUrl = useGotoUrl();

  useEffect(() => {
    const currentUser = data?.currentUser;
    if (currentUser && enforceUnauthenticated)
      Promise.resolve().then(async () => {
        let redirectToPath = location.search.match(/redirect=([^&]+)/)?.[1];
        if (redirectToPath) redirectToPath = decodeURIComponent(redirectToPath);
        redirectToPath = redirectToPath || DEFAULT_REDIRECT_PATH;
        gotoUrl(redirectToPath, { replace: true });
      });
    if (data && !data.currentUser && enforceAuthenticated) {
      let redirectToPath = location.pathname;
      if (redirectToPath === DEFAULT_REDIRECT_PATH) redirectToPath = "";
      gotoUrl(
        redirectToPath
          ? //
            `/readmin/login?redirect=${encodeURIComponent(redirectToPath)}`
          : "/readmin/login",
        {
          replace: true,
        },
      );
    }
  }, [data]);

  const enforceAuth = {
    loading: !data,
    refetch: dataMeta.refetch,
  };
  return enforceAuth;
}
