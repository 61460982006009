import ApiPartnerGraphiqlPage from "readmin_pages/ApiPartnerGraphiqlPage";
import ReadminDashboard from "readmin_pages/dashboard/ReadminDashboard";
import GamesRefereeManagementPage from "readmin_pages/games/GamesRefereeManagementPage";
import OpsWarningsPage from "readmin_pages/ops_warnings/OpsWarningsPage";
import {
  AdAssetPage,
  AdPresetPage,
  ApiPartnerApiCompetitionPage,
  ApiPartnerApiCourtPage,
  ApiPartnerApiGameSchedulePage,
  ApiPartnerApiGradePage,
  ApiPartnerApiGraphqlRequestPage,
  ApiPartnerApiOrganisationPage,
  ApiPartnerApiProfilePage,
  ApiPartnerApiTeamPage,
  ApiPartnerPage,
  BetaFeaturePage,
  CameraModelPage,
  CameraPage,
  CityPage,
  ClubAdminPage,
  ClubPage,
  CourtPage,
  DeliveryPage,
  DevicePortMappingPage,
  EventPage,
  EventVideoPage,
  GamePage,
  GameSubmissionPage,
  GdAssociationPage,
  GdVenuePage,
  IncidentPage,
  LeaderboardTablePage,
  LeaguePage,
  LiveStreamPage,
  MotioncropTestPage,
  MotioncropTestReportPage,
  NetworkDevicePage,
  OnsenPopupPromoPage,
  OriginalVideoRequestPage,
  PersonPage,
  PlayHqOrganisationPage,
  PotwCompetitionPage,
  PotwEntryPage,
  PotwRatingPage,
  PotwVideoPage,
  PublicLeaguePagePage,
  RecordingBlockPage,
  RecordingPage,
  RecurringRecordingOccurrencePage,
  ScorekeeperDevicePage,
  ScorekeeperUserPage,
  SendgridEventPage,
  SurfacePage,
  TeamPage,
  TeamRegistrationPage,
  UsageReportEntryPage,
  UserPage,
  VenueAdminPage,
  VenuePage,
  VenueServerChefRunPage,
  VenueServerCommandPage,
  VenueServerPage,
  VenueServerVideoFilePage,
  VideoCutAttemptPage,
  VideoEncodingJobRequestPage,
  VideoObjectPage,
  VideoPage,
  VideoVersionPage,
} from "readmin_pages/shared/readminObjectPages";
import {
  AdAssetsPage,
  AdPresetsPage,
  ApiPartnerApiCompetitionsPage,
  ApiPartnerApiCourtsPage,
  ApiPartnerApiGameSchedulesPage,
  ApiPartnerApiGraphqlRequestsPage,
  ApiPartnerApiOrganisationsPage,
  ApiPartnerApiProfilesPage,
  ApiPartnerApiTeamsPage,
  ApiPartnersPage,
  BetaFeaturesPage,
  CameraModelsPage,
  CamerasPage,
  CitiesPage,
  ClubAdminsPage,
  ClubsPage,
  CourtsPage,
  DeliveriesPage,
  DevicePortMappingsPage,
  EventsPage,
  EventVideosPage,
  GamesPage,
  GameSubmissionsPage,
  GdAssociationsPage,
  GdVenuesPage,
  IncidentsPage,
  LeaguesPage,
  LiveStreamsPage,
  MotioncropTestReportsPage,
  MotioncropTestsPage,
  NetworkDevicesPage,
  NotesPage,
  OnsenPopupPromosPage,
  OriginalVideoRequestsPage,
  PaperTrailVersionsPage,
  PeoplePage,
  PlayHqOrganisationsPage,
  PotwCompetitionsPage,
  PotwEntriesPage,
  PotwRatingsPage,
  PotwVideosPage,
  PublicLeaguePagesPage,
  RecordingBlocksPage,
  RecordingsPage,
  RecurringRecordingOccurrencesPage,
  ScorekeeperDevicesPage,
  ScorekeeperUsersPage,
  SendgridEventsPage,
  SurfacesPage,
  TeamRegistrationsPage,
  TeamsPage,
  UsageReportPage,
  UsersPage,
  VenueAdminsPage,
  VenueServerChefRunsPage,
  VenueServerCommandsPage,
  VenueServersPage,
  VenuesPage,
  VideoCutAttemptsPage,
  VideoObjectsPage,
  VideosPage,
} from "readmin_pages/shared/readminObjectsPages";

const READMIN_ROUTES = {
  // readmin, special pages
  "/": ReadminDashboard,
  "/ops_warnings": OpsWarningsPage,

  // readmin root-level pages
  "/ad_assets": AdAssetsPage,
  "/ad_presets": AdPresetsPage,
  "/beta_features": BetaFeaturesPage,
  "/beta_features/:betaFeatureId": BetaFeaturePage,
  "/cameras": CamerasPage,
  "/cameras/:cameraId": CameraPage,
  "/camera_models": CameraModelsPage,
  "/camera_models/:cameraModelId": CameraModelPage,
  "/cities": CitiesPage,
  "/cities/:cityId": CityPage,
  "/clubs": ClubsPage,
  "/club_admins": ClubAdminsPage,
  "/courts": CourtsPage,
  "/deliveries": DeliveriesPage,
  "/deliveries/:deliveryId": DeliveryPage,
  "/video_encoding_job_requests/:videoEncodingJobRequestId": VideoEncodingJobRequestPage,
  "/events": EventsPage,
  "/event_videos": EventVideosPage,
  "/event_videos/:eventVideoId": EventVideoPage,
  "/games": GamesPage,
  "/game_submissions": GameSubmissionsPage,
  "/game_submissions/:gameSubmissionId": GameSubmissionPage,
  "/incidents": IncidentsPage,
  "/leagues": LeaguesPage,
  "/live_streams": LiveStreamsPage,
  "/network_devices": NetworkDevicesPage,
  "/network_devices/:networkDeviceId": NetworkDevicePage,
  "/motioncrop_tests": MotioncropTestsPage,
  "/motioncrop_tests/:motioncropTestId": MotioncropTestPage,
  "/motioncrop_test_reports": MotioncropTestReportsPage,
  "/motioncrop_test_reports/:motioncropTestReportId": MotioncropTestReportPage,
  "/notes": NotesPage,
  "/original_video_requests": OriginalVideoRequestsPage,
  "/original_video_requests/:originalVideoRequestId": OriginalVideoRequestPage,
  "/onsen_popup_promos": OnsenPopupPromosPage,
  "/onsen_popup_promos/:onsenPopupPromoId": OnsenPopupPromoPage,
  "/people": PeoplePage,
  "/paper_trail_versions": PaperTrailVersionsPage,
  "/play_hq_organisations": PlayHqOrganisationsPage,
  "/play_hq_organisations/:playHqOrganisationId": PlayHqOrganisationPage,
  "/potw_competitions": PotwCompetitionsPage,
  "/potw_entries": PotwEntriesPage,
  "/potw_ratings": PotwRatingsPage,
  "/potw_videos": PotwVideosPage,
  "/potw_videos/:potwVideoId": PotwVideoPage,
  "/public_league_pages": PublicLeaguePagesPage,
  "/recording_blocks": RecordingBlocksPage,
  "/recording_blocks/:recordingBlockId": RecordingBlockPage,
  "/recurring_recording_occurrences": RecurringRecordingOccurrencesPage,
  "/scorekeeper_devices": ScorekeeperDevicesPage,
  "/scorekeeper_devices/:scorekeeperDeviceId": ScorekeeperDevicePage,
  "/scorekeeper_users": ScorekeeperUsersPage,
  "/sendgrid_events": SendgridEventsPage,
  "/sendgrid_events/:sendgridEventId": SendgridEventPage,
  "/surfaces": SurfacesPage,
  "/surfaces/:surfaceId": SurfacePage,
  "/teams": TeamsPage,
  "/team_registrations": TeamRegistrationsPage,
  "/users": UsersPage,
  "/users/:userId": UserPage,
  "/venue_admins": VenueAdminsPage,
  "/venue_admins/:venueAdminId": VenueAdminPage,
  "/venue_server_commands": VenueServerCommandsPage,
  "/venue_server_commands/:venueServerCommandId": VenueServerCommandPage,
  "/venue_server_chef_runs": VenueServerChefRunsPage,
  "/venue_server_chef_runs/:venueServerChefRunId": VenueServerChefRunPage,
  "/venue_servers": VenueServersPage,
  "/venue_servers/:venueServerId": VenueServerPage,
  "/venue_server_video_files/:venueServerVideoFileId": VenueServerVideoFilePage,
  "/venues": VenuesPage,
  "/venues/:venueId": VenuePage,
  "/videos": VideosPage,
  "/videos/:videoId": VideoPage,
  "/video_cut_attempts": VideoCutAttemptsPage,
  "/video_cut_attempts/:videoCutAttemptId": VideoCutAttemptPage,
  "/video_objects": VideoObjectsPage,
  "/video_objects/:videoObjectId": VideoObjectPage,
  "/video_versions/:videoVersionId": VideoVersionPage,
  "/gd_associations": GdAssociationsPage,
  "/gd_associations/:gdAssociationId": GdAssociationPage,
  "/gd_venues": GdVenuesPage,
  "/gd_venues/:gdVenueId": GdVenuePage,
  "/device_port_mappings": DevicePortMappingsPage,
  "/device_port_mappings/:devicePortMappingId": DevicePortMappingPage,

  // readmin club-level pages
  "/clubs/:clubId": ClubPage,
  "/clubs/:clubId/games/manage_referees": GamesRefereeManagementPage,
  // resources
  "/clubs/:clubId/ad_assets": AdAssetsPage,
  "/clubs/:clubId/ad_assets/:adAssetId": AdAssetPage,
  "/clubs/:clubId/ad_presets": AdPresetsPage,
  "/clubs/:clubId/ad_presets/:adPresetId": AdPresetPage,
  "/clubs/:clubId/deliveries": DeliveriesPage,
  "/clubs/:clubId/deliveries/:deliveryId": DeliveryPage,
  "/clubs/:clubId/teams": TeamsPage,
  "/clubs/:clubId/teams/:teamId": TeamPage,
  "/clubs/:clubId/team_registrations": TeamRegistrationsPage,
  "/clubs/:clubId/team_registrations/:teamRegistrationId": TeamRegistrationPage,
  "/clubs/:clubId/games": GamesPage,
  "/clubs/:clubId/games/:gameId": GamePage,
  "/clubs/:clubId/game_submissions": GameSubmissionsPage,
  "/clubs/:clubId/game_submissions/:gameSubmissionId": GameSubmissionPage,
  "/clubs/:clubId/videos": VideosPage,
  "/clubs/:clubId/videos/:videoId": VideoPage,
  "/clubs/:clubId/incidents": IncidentsPage,
  "/clubs/:clubId/incidents/:incidentId": IncidentPage,
  "/clubs/:clubId/leagues": LeaguesPage,
  "/clubs/:clubId/leagues/:leagueId": LeaguePage,
  "/clubs/:clubId/live_streams": LiveStreamsPage,
  "/clubs/:clubId/live_streams/:liveStreamId": LiveStreamPage,
  "/clubs/:clubId/leaderboard_tables/:leaderboardTableId": LeaderboardTablePage,
  "/clubs/:clubId/people": PeoplePage,
  "/clubs/:clubId/people/:personId": PersonPage,
  "/clubs/:clubId/potw_competitions": PotwCompetitionsPage,
  "/clubs/:clubId/potw_competitions/:potwCompetitionId": PotwCompetitionPage,
  "/clubs/:clubId/potw_entries": PotwEntriesPage,
  "/clubs/:clubId/potw_entries/:potwEntryId": PotwEntryPage,
  "/clubs/:clubId/potw_ratings": PotwRatingsPage,
  "/clubs/:clubId/potw_ratings/:potwRatingId": PotwRatingPage,
  "/clubs/:clubId/public_league_pages": PublicLeaguePagesPage,
  "/clubs/:clubId/public_league_pages/:publicLeaguePageId": PublicLeaguePagePage,
  "/clubs/:clubId/recurring_recording_occurrences": RecurringRecordingOccurrencesPage,
  "/clubs/:clubId/recurring_recording_occurrences/:recurringRecordingOccurrenceId": RecurringRecordingOccurrencePage,
  "/clubs/:clubId/courts": CourtsPage,
  "/clubs/:clubId/courts/:courtId": CourtPage,
  "/clubs/:clubId/recordings": RecordingsPage,
  "/clubs/:clubId/recordings/:recordingId": RecordingPage,
  "/clubs/:clubId/scorekeeper_users": ScorekeeperUsersPage,
  "/clubs/:clubId/scorekeeper_users/:scorekeeperUserId": ScorekeeperUserPage,
  "/clubs/:clubId/events": EventsPage,
  "/clubs/:clubId/events/:eventId": EventPage,
  "/clubs/:clubId/notes": NotesPage,
  "/clubs/:clubId/usage_report": UsageReportPage,
  "/clubs/:clubId/usage_report_entries/:usageReportEntryId": UsageReportEntryPage,
  "/clubs/:clubId/club_admins": ClubAdminsPage,
  "/clubs/:clubId/club_admins/:clubAdminId": ClubAdminPage,
  "/clubs/:clubId/venues/:venueId": VenuePage,

  // readmin api-level pages
  "/api_partners": ApiPartnersPage,
  "/api_partners/:apiPartnerId": ApiPartnerPage,
  "/api_partners/:apiPartnerId/graphiql": ApiPartnerGraphiqlPage,
  // resources
  "/api_partners/:apiPartnerId/api_organisations": ApiPartnerApiOrganisationsPage,
  "/api_partners/:apiPartnerId/api_organisations/:apiOrganisationId": ApiPartnerApiOrganisationPage,
  "/api_partners/:apiPartnerId/api_courts": ApiPartnerApiCourtsPage,
  "/api_partners/:apiPartnerId/api_courts/:apiCourtId": ApiPartnerApiCourtPage,
  "/api_partners/:apiPartnerId/api_teams": ApiPartnerApiTeamsPage,
  "/api_partners/:apiPartnerId/api_teams/:apiTeamId": ApiPartnerApiTeamPage,
  "/api_partners/:apiPartnerId/api_competitions": ApiPartnerApiCompetitionsPage,
  "/api_partners/:apiPartnerId/api_competitions/:apiCompetitionId": ApiPartnerApiCompetitionPage,
  "/api_partners/:apiPartnerId/api_game_schedules": ApiPartnerApiGameSchedulesPage,
  "/api_partners/:apiPartnerId/api_game_schedules/:apiGameScheduleId": ApiPartnerApiGameSchedulePage,
  "/api_partners/:apiPartnerId/api_profiles": ApiPartnerApiProfilesPage,
  "/api_partners/:apiPartnerId/api_profiles/:apiProfileId": ApiPartnerApiProfilePage,
  "/api_partners/:apiPartnerId/api_grades/:apiGradeId": ApiPartnerApiGradePage,
  "/api_partners/:apiPartnerId/api_graphql_requests": ApiPartnerApiGraphqlRequestsPage,
  "/api_partners/:apiPartnerId/api_graphql_requests/:apiGraphqlRequestId": ApiPartnerApiGraphqlRequestPage,
};

export default READMIN_ROUTES;
