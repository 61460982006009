import { gql } from "@apollo/client";
import Message from "controls/Message";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import React from "react";

import ReadminObjectsTable, { ReadminObjectsTableFragment } from "../shared/ReadminObjectsTable";

export default function ReadminDashboardRoles() {
  const isAdmin = useIsAdmin();
  const [data, dataMeta] = useData(gql`
    query ReadminDashboardRoles {
      currentUser {
        id
        clubAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        venueAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        apiAdmins {
          id
          ...ReadminObjectsTableFragment
        }
      }
      onsenUrl
    }
    ${ReadminObjectsTableFragment}
  `);

  return (
    <>
      {isAdmin === false &&
        data?.currentUser?.clubAdmins.length === 0 &&
        data?.currentUser?.venueAdmins.length === 0 &&
        data?.currentUser?.apiAdmins.length === 0 && (
          <Message
            type="error"
            title="No Access"
            content={
              <>
                You do no have access to Glory League Admin, did you mean to log into our{" "}
                <a href={data?.onsenUrl}>Player Portal?</a>
              </>
            }
          />
        )}
      {isAdmin && <Message content={<>You have admin access to Glory League</>} />}
      {!!data?.currentUser?.clubAdmins.length && (
        <>
          <Message title="Club Admin Roles" content="You have administrative access to these clubs." />
          <ReadminObjectsTable
            typename="ClubAdmin"
            rows={data?.currentUser?.clubAdmins}
            onSubmit={() => dataMeta.refetch()}
          />
        </>
      )}
      {!!data?.currentUser?.venueAdmins.length && (
        <>
          <Message
            title="Club Admin Roles"
            content="You have administrative access to infrastructure of these venues."
          />
          <ReadminObjectsTable
            header="Venue Admin Roles"
            typename="VenueAdmin"
            rows={data?.currentUser?.venueAdmins}
            onSubmit={() => dataMeta.refetch()}
          />
        </>
      )}
      {!!data?.currentUser?.apiAdmins.length && (
        <>
          <Message title="API Admin Roles" content="You have administrative access to these APIs." />
          <ReadminObjectsTable
            typename="ApiAdmin"
            rows={data?.currentUser?.apiAdmins}
            onSubmit={() => dataMeta.refetch()}
          />
        </>
      )}
    </>
  );
}
