import DataTable from "controls/DataTable";
import { VideoThumbnailLoad } from "controls/VideoThumbnail";
import { useActionFragment } from "hooks/useAction";
import React from "react";

export default function SurfaceMotioncropPreviewTab({ surfaceId }) {
  const surfacePreviewMotionCrop = useActionFragment("surfacePreviewMotionCrop", "jpgImageBase64");

  return (
    <>
      <DataTable
        defaultMode="grid"
        columns={["Preview Name"]}
        rows={[
          ["Full Court Debug Preview", { surfaceId }],
          ["Left Court", { surfaceId, projectedPosition: "left" }],
          ["Middle Court", { surfaceId, projectedPosition: "middle" }],
          ["Right Court", { surfaceId, projectedPosition: "right" }],
        ].map(([label, input]) => ({
          key: label,
          cells: [label],
          thumbnail: (
            <VideoThumbnailLoad
              style={{ maxHeight: 320, maxWidth: "100%" }}
              loadCallback={async () => {
                const result = await surfacePreviewMotionCrop({ input });
                const jpgImageBase64 = result.surfacePreviewMotionCrop.jpgImageBase64;
                return { base64: jpgImageBase64 };
              }}
            />
          ),
        }))}
      />
    </>
  );
}
