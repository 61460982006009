import { gql } from "@apollo/client";
import apolloClient from "helpers/apolloClient";
import useData from "hooks/useData";
import { progressBar } from "hooks/useProgressBar";
import imgLogoNew from "images/logo-new.png";
import { Account, Logout, Refresh, SwapHorizontal } from "mdi-material-ui";
import React from "react";
import { ReadminMenuDropdown, ReadminMenuDropdownItem, ReadminMenuImageItem, ReadminMenuItem } from "Readmin";

export default function ReadminMenuContent() {
  const [data] = useData(gql`
    query ReadminMenuContent {
      currentUser {
        id
        email
      }
      onsenUrl
    }
  `);

  return (
    <>
      <ReadminMenuImageItem src={imgLogoNew} href="/readmin" label="Glory League" />
      <div style={{ flex: "1 1 auto" }} />
      {data?.currentUser && (
        <ReadminMenuDropdown alignOnRight icon={<Account />} label={data.currentUser.email}>
          <ReadminMenuDropdownItem icon={<SwapHorizontal />} label="Switch to Player Portal" href={data.onsenUrl} />
          <ReadminMenuDropdownItem icon={<Account />} label="Dashboard" href="/readmin" />
          <ReadminMenuDropdownItem icon={<Logout />} label="Logout" href="/readmin/logout" />
        </ReadminMenuDropdown>
      )}
      {data && !data.currentUser && <ReadminMenuItem label="Login" href="/readmin/login" />}
      <ReadminMenuItem
        icon={<Refresh className="progressbar-spinner" />}
        onClick={() => progressBar(() => apolloClient.refetchQueries({ include: "active" }))}
      />
    </>
  );
}
