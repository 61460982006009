import { gql } from "@apollo/client";
import FilterButton from "filters/FilterButton";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import useRouteState from "hooks/useRouteState";
import useTimeZone from "hooks/useTimeZone";
import { Download } from "mdi-material-ui";
import React from "react";
import { useParams } from "react-router-dom";
import ViewReadminCsvExportDialog from "readmin_pages/csv_exports/ViewReadminCsvExportDialog";
import ReadminPage from "readmin_pages/ReadminPage";

import ReadminObjectsDialogToolbar from "./ReadminObjectsDialogToolbar";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "./ReadminObjectsTable";
import useReadminObjectTypedef from "./useReadminObjectTypedef";

export default function makeReadminObjectsPage(
  typename,
  typePlural,
  { title = null, tableDefaultMode = "table" } = {},
) {
  return function () {
    return (
      <ReadminObjectsPage
        typename={typename}
        typePlural={typePlural}
        title={title}
        tableDefaultMode={tableDefaultMode}
      />
    );
  };
}

function ReadminObjectsPage({ typename, typePlural, title, tableDefaultMode }) {
  const { apiPartnerId, clubId } = useParams();
  const [limit, limitSet] = useRouteState("limit", 10);
  const readminObjectTypedef = useReadminObjectTypedef(typename, {
    loadFilterBar: true,
  });

  const [countData, countDataMeta] = useData(
    gql`
      query ReadminObjectsPageContent_count(
        $apiPartnerId: ID
        $clubId: ID
        $typename: String!
        $filterBar: ReadminFilterBar
      ) {
        readminObjectsCount(apiPartnerId: $apiPartnerId, clubId: $clubId, typename: $typename, filterBar: $filterBar)
      }
    `,
    {
      apiPartnerId,
      clubId,
      typename,
      filterBar: readminObjectTypedef?.filterBarVariables,
    },
    { skip: !readminObjectTypedef },
  );
  const [objectsData, objectsDataMeta] = useData(
    gql`
      query ReadminObjectsPageContent(
        $apiPartnerId: ID
        $clubId: ID
        $typename: String!
        $filterBar: ReadminFilterBar
        $limit: Int!
        $offset: Int
      ) {
        readminObjects(
          apiPartnerId: $apiPartnerId
          clubId: $clubId
          typename: $typename
          filterBar: $filterBar
          limit: $limit
          offset: $offset
        ) {
          id
          ...ReadminObjectsTableFragment
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      apiPartnerId,
      clubId,
      typename,
      filterBar: readminObjectTypedef?.filterBarVariables,
      limit,
    },
    {
      skip: !readminObjectTypedef,
    },
  );

  const exportReadminObjectsCsv = useActionFragment(
    "exportReadminObjectsCsv",
    "readminCsvExport { id }",
    "currentUser { id readminCsvExports { id } }",
  );
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const timeZone = useTimeZone();

  return (
    <ReadminPage apiPartnerId={apiPartnerId} clubId={clubId} title={title || typePlural}>
      {formDialogs}
      <ReadminObjectsDialogToolbar
        typename={typename}
        gotoReadminObjectUrl
        onSubmit={async () => {
          if (countData) await countDataMeta.refetch();
          if (objectsData) await objectsDataMeta.refetch();
        }}
      />
      <FiltersBar>
        {readminObjectTypedef?.filterBarContent}
        <PageSizeFilter value={limit} onChange={limitSet} />
        <FilterButton
          icon={<Download />}
          content="Export as CSV"
          onClick={async () => {
            const result = await exportReadminObjectsCsv({
              input: {
                title: title || typePlural,
                timeZone,
                gqlVariables: {
                  clubId,
                  apiPartnerId,
                  typename,
                  filterBar: readminObjectTypedef?.filterBarVariables,
                },
              },
            });
            const readminCsvExportId = result.exportReadminObjectsCsv.readminCsvExport.id;
            await formDialogsOpen(<ViewReadminCsvExportDialog readminCsvExportId={readminCsvExportId} />);
          }}
        />
      </FiltersBar>
      <ReadminObjectsTable
        defaultMode={tableDefaultMode}
        typename={typename}
        dataMeta={objectsDataMeta}
        rowsCount={countData?.readminObjectsCount}
        rows={objectsData?.readminObjects}
        onSubmit={async () => {
          if (countData) await countDataMeta.refetch();
          if (objectsData)
            objectsDataMeta.refetch({
              offset: 0,
              limit: objectsData.readminObjects.length,
            });
        }}
      />
    </ReadminPage>
  );
}
