import { Button, Dialog, Skeleton } from "@mui/material";
import { dialogContext } from "helpers/contexts";
import getFormData from "helpers/getFormData";
import useIsAdmin from "hooks/useIsAdmin";
import useShowMessage from "hooks/useShowMessage";
import { times } from "lodash-es";
import { Check, Plus, Store } from "mdi-material-ui";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function FormDialog({
  submitIcon,
  submitContent,
  submitType = "ok",
  onSubmit,
  onClose,
  header,
  children,
  disabled,
  loading = false,
  ...others
}) {
  const isAdmin = useIsAdmin();
  const { clubId } = useParams();
  const [submitting, submittingSet] = useState(false);
  const showMessage = useShowMessage();
  if (!submitIcon)
    submitIcon = {
      add: <Plus />,
      edit: <Store />,
    }[submitType] || <Check />;
  if (!submitContent)
    submitContent =
      {
        add: "Add",
        edit: "Save",
      }[submitType] || "OK";

  return (
    <dialogContext.Provider value={{ isInDialog: true }}>
      <Dialog
        maxWidth="md"
        fullWidth
        open
        onClose={(event, reason) => {
          if (reason === "backdropClick") return;
          onClose?.(event, reason);
        }}
        scroll="body"
        {...others}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            event.stopPropagation();
            let formData;
            try {
              formData = getFormData(event.currentTarget);
            } catch (error) {
              showMessage({ header: "Cannot submit", content: error.message, modal: true });
              // eslint-disable-next-line no-console
              console.error(error);
              return;
            }
            submittingSet(true);
            try {
              await onSubmit?.(formData);
              window.gtag?.("event", "form_dialog_submit", { header, is_admin: isAdmin, club_id: clubId });
            } finally {
              submittingSet(false);
            }
            onClose?.();
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
            padding: 10,
          }}
        >
          {header && (
            <div
              style={{
                padding: 10,
                fontSize: "1.2em",
                fontWeight: "bold",
              }}
            >
              {header}
            </div>
          )}
          {loading && times(5).map((i) => <Skeleton key={i} variant="rectangular" />)}
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              gap: 10,
            }}
          >
            {!loading && children}
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-end",
            }}
          >
            {onSubmit ? (
              <>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={disabled || submitting || loading}
                  startIcon={submitIcon}
                  autoFocus
                >
                  {submitContent}
                </Button>
                <Button onClick={onClose} color="inherit">
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button onClick={onClose}>
                  <Check />
                  OK
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </dialogContext.Provider>
  );
}

export function ConfirmFormDialog({ action, children, ...others }) {
  return (
    <FormDialog header={action} submitContent={action || "Confirm"} {...others} fullWidth={false}>
      <div style={{ padding: 10 }}>
        {action ? (
          <>
            Are you sure you want to <b>{action}</b>?
          </>
        ) : (
          <>Are you sure?</>
        )}
        <br />
        <br />
        {children}
      </div>
    </FormDialog>
  );
}

export function FormDialogButtons({ children }) {
  return <div style={{ display: "flex", gap: 10 }}>{children}</div>;
}

export function FormDialogColumns({ children }) {
  return (
    <div
      style={{
        maxWidth: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "stretch",
        gap: 10,
      }}
    >
      {children}
    </div>
  );
}

export function FormDialogColumn({ children, span = 1 }) {
  return (
    <div
      style={{
        maxWidth: "100%",
        flex: `${span} ${span} 0`,
        minWidth: 400,
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        gap: 10,
      }}
    >
      {children}
    </div>
  );
}
