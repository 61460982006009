import { Button } from "@mui/material";
import { RAILS_ENV } from "helpers/constants";
import useGotoUrl from "hooks/useGotoUrl";
import React, { useLayoutEffect } from "react";

import PublicForm from "./PublicForm";

export default function RootPage() {
  const gotoUrl = useGotoUrl();

  const redirectTo = ["production"].includes(RAILS_ENV) ? "https://gloryleague.basketball/" : null;

  useLayoutEffect(() => {
    if (redirectTo) {
      gotoUrl(redirectTo);
    }
  }, [redirectTo]);

  if (redirectTo) return <PublicForm dense loading />;

  return (
    <PublicForm title="Glory League" dense>
      {[
        //
        ["Readmin", "/readmin"],
      ].map(([label, path]) => (
        <Button href={path} key={path} variant="contained" color="primary" fullWidth size="large">
          {label}
        </Button>
      ))}
    </PublicForm>
  );
}
