import { gql } from "@apollo/client";
import { Alert, AlertTitle } from "@mui/material";
import useData from "hooks/useData";
import React from "react";

import ReadminLabelDetails, { ReadminLabelDetailsFragement } from "./ReadminLabelDetails";

export const ReadminObjectLabelDescriptionsFragment = gql`
  fragment ReadminObjectLabelDescriptionsFragment on ReadminObject {
    readminLabels {
      content
      detail
      description
      type
      ...ReadminLabelDetailsFragement
    }
  }
  ${ReadminLabelDetailsFragement}
`;

export default function ReadminObjectLabelDescriptions({ id, typename, readminObject }) {
  const [data] = useData(
    gql`
      query ReadminObjectLabelDescriptions($typename: String!, $id: ID!) {
        readminObject(typename: $typename, id: $id) {
          id
          ...ReadminObjectLabelDescriptionsFragment
        }
      }
      ${ReadminObjectLabelDescriptionsFragment}
    `,
    {
      typename,
      id,
    },
    {
      skip: !!readminObject || !id || !typename,
    },
  );

  readminObject ||= data?.readminObject;

  return (
    <>
      {readminObject?.readminLabels
        .filter((l) => l.description)
        .map((label, labelIndex) => (
          <Alert
            key={labelIndex}
            severity={
              {
                warning: "warning",
                success: "success",
                error: "error",
              }[label.type] || "info"
            }
            variant="outlined"
          >
            <AlertTitle>{[label.content, label.detail && `(${label.detail})`].join(" ")}</AlertTitle>
            <ReadminLabelDetails label={label} />
          </Alert>
        ))}
    </>
  );
}
