import { gql } from "@apollo/client";
import DefinitionTable from "controls/DefinitionTable";
import { DialogToolbarButton } from "controls/DialogsToolbar";
import Message from "controls/Message";
import Toolbar, { ToolbarButton } from "controls/Toolbar";
import oauthAuthorise from "helpers/oauthAuthorise";
import useData from "hooks/useData";
import { Link, LinkOff } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";
import { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";

export function LiveStreamYtAuthStatus() {
  const [data, dataMeta] = useData(gql`
    query LiveStreamYtAuthStatus {
      currentUser {
        id
        clubAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        venueAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        liveStreamYtAuth {
          id
          name
          email
        }
      }
    }
    ${ReadminObjectsTableFragment}
  `);

  return (
    <>
      {data?.currentUser && !data?.currentUser?.liveStreamYtAuth && (
        <Message content="You have not connected YouTube account for live streaming." />
      )}
      {data?.currentUser?.liveStreamYtAuth && (
        <DefinitionTable
          style={{ margin: "0 -10px" }}
          rows={[
            ["Name", data.currentUser.liveStreamYtAuth.name],
            ["Email", data.currentUser.liveStreamYtAuth.email],
          ]}
        />
      )}
      <Toolbar>
        <ToolbarButton
          icon={<Link />}
          content={data?.currentUser?.liveStreamYtAuth ? "Re-connect YouTube Account" : "Connect YouTube Account"}
          onClick={() => oauthAuthorise("youtube_live_stream_auth")}
        />
        <DialogToolbarButton dialog={<RemoveLiveStreamYtAuthDialog />} onSubmit={() => dataMeta.refetch()} />
      </Toolbar>
    </>
  );
}

const RemoveLiveStreamYtAuthDialog = makeConfirmActionDialog({
  mutationName: "currentUserRemoveLiveStreamYtAuth",
  action: "Disconnect YouTube Account",
  useButtonProps: () => {
    const [data] = useData(gql`
      query CurrentUserRemoveLiveStreamYtAuthDialog {
        currentUser {
          liveStreamYtAuth {
            id
          }
        }
      }
    `);

    return {
      content: "Disconnect YouTube Account",
      hidden: !data?.currentUser?.liveStreamYtAuth,
      icon: <LinkOff />,
    };
  },
});
