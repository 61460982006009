import { gql } from "@apollo/client";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import useData from "hooks/useData";
import useRouteState from "hooks/useRouteState";
import React from "react";

import ReadminObjectsDialogToolbar from "./ReadminObjectsDialogToolbar";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "./ReadminObjectsTable";
import useReadminObjectTypedef from "./useReadminObjectTypedef";

ReadminAssociationTableTab.useTabOptions = ({ id, typename, associationName }) => {
  const readminObjectTypedef = useReadminObjectTypedef(typename, { loadAssociations: true });
  const association = readminObjectTypedef?.associations?.find((a) => a.name === associationName);
  const associationTypedef = useReadminObjectTypedef(association?.typename);
  const [data] = useData(
    gql`
      query ReadminAssociationTableTabOptions_${associationName}($id: ID!, $typename: String!, $associationName: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          readminAssociationObjectsAny(associationName: $associationName)
        }
      }
    `,
    {
      id,
      typename,
      associationName,
    },
    {
      // Do not have permission to view this type, skip querying
      skip: !associationTypedef,
    },
  );

  return {
    dot: !!data?.readminObject?.readminAssociationObjectsAny,
    // Hide the tab if there's no permission or association not available
    hidden: !associationTypedef || data?.readminObject?.readminAssociationObjectsAny === null,
  };
};

export default function ReadminAssociationTableTab({ id, typename, associationName, tableDefaultMode = "table" }) {
  const [limit, limitSet] = useRouteState("limit", 10);
  const readminObjectTypedef = useReadminObjectTypedef(typename, { loadAssociations: true });
  const association = readminObjectTypedef?.associations?.find((a) => a.name === associationName);
  const associationTypedef = useReadminObjectTypedef(association?.typename, { loadFilterBar: true });

  const [data, dataMeta] = useData(
    gql`
      query ReadminAssociationTab_${associationName}(
        $id: ID!
        $typename: String!
        $associationName: String!
        $filterBar: ReadminFilterBar
        $limit: Int!
        $offset: Int
      ) {
        readminObject(id: $id, typename: $typename) {
          id
          readminAssociationObjectsCount(associationName: $associationName, filterBar: $filterBar)
          readminAssociationObjects(
            associationName: $associationName
            filterBar: $filterBar
            limit: $limit
            offset: $offset
          ) {
            id
            ...ReadminObjectsTableFragment
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      id,
      typename,
      associationName,
      filterBar: associationTypedef?.filterBarVariables,
      limit,
    },
    {
      skip: !readminObjectTypedef || !associationTypedef,
    },
  );

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        gap: 10,
      }}
    >
      <ReadminObjectsDialogToolbar
        parentId={id}
        parentTypename={typename}
        typename={association?.typename}
        gotoReadminObjectUrl
        onSubmit={async () => {
          if (data) await dataMeta.refetch();
        }}
      />
      <FiltersBar>
        {associationTypedef?.filterBarContent}
        <PageSizeFilter value={limit} onChange={limitSet} />
      </FiltersBar>
      <ReadminObjectsTable
        typename={association?.typename}
        ignoreAssociationObjects={[data?.readminObject]}
        defaultMode={tableDefaultMode}
        dataMeta={dataMeta}
        rowsCount={data?.readminObject?.readminAssociationObjectsCount}
        rows={data?.readminObject?.readminAssociationObjects}
        onSubmit={async () => {
          if (data) await dataMeta.refetch();
        }}
      />
    </div>
  );
}
