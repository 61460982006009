import { gql } from "@apollo/client";
import DateTime from "controls/DateTime";
import Message from "controls/Message";
import Toolbar, { ToolbarButton } from "controls/Toolbar";
import useData from "hooks/useData";
import { Download } from "mdi-material-ui";
import React from "react";
import { useParams } from "react-router-dom";

import PublicForm from "./PublicForm";

export default function OriginalVideoFormPage() {
  const { uuid } = useParams();
  const [data] = useData(
    gql`
      query OriginalVideoFormPage($uuid: String!) {
        originalVideoForm(uuid: $uuid) {
          id
          gameTitle
          courtTitle
          gamePlayedAt
          restoring
          downloadUrl
        }
      }
    `,
    { uuid },
  );

  return (
    <PublicForm title="Download Original Video" loading={!data}>
      {data && data.originalVideoForm === null && (
        <Message type="error" title="Invalid Request" content="This link is invalid or has expired." />
      )}
      {data?.originalVideoForm && (
        <>
          <div>We have received your request to download the original video of following game:</div>
          <div>
            <b>{data?.originalVideoForm.gameTitle}</b> <DateTime value={data?.originalVideoForm.gamePlayedAt} /> at{" "}
            {data?.originalVideoForm.courtTitle}
          </div>
          {data?.originalVideoForm.downloadUrl && (
            <Message
              type="success"
              title="Download Available"
              content={
                <>The original video is available for download. Please click the button below to download the video.</>
              }
            />
          )}
          {data?.originalVideoForm.restoring && (
            <Message
              type="info"
              title="Restoring"
              content={
                <>
                  We are still restoring the original video from archive. The restoring process may take up to 12 hours.
                  Please check back later.
                </>
              }
            />
          )}
          {data?.originalVideoForm.downloadUrl === null && data?.originalVideoForm.restoring === false && (
            <Message
              type="error"
              title="Video Archived"
              content={
                <>
                  The original video has already been archived and is no longer available for download. If you need the
                  video, please contact us.
                </>
              }
            />
          )}
          <Toolbar>
            <ToolbarButton
              disabled={!data?.originalVideoForm.downloadUrl}
              href={data?.originalVideoForm.downloadUrl}
              target="_blank"
              icon={<Download />}
              content="Download Original Video"
            />
          </Toolbar>
        </>
      )}
    </PublicForm>
  );
}
