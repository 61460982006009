import {
  AppBar,
  Button,
  ButtonBase,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import Scrollable from "controls/Scrollable";
import { COLOR_SECONDARY } from "helpers/muiTheme";
import useEnforceAuth from "hooks/useEnforceAuth";
import { ChevronDoubleRight, ChevronDown, MenuDown, Pin } from "mdi-material-ui";
import PublicForm from "public_pages/PublicForm";
import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use-size";
import ReactPageFallthrough from "ReactPageFallthrough";
import ReadminMenuContent from "readmin_pages/ReadminMenuContent";
import ReadminSidebarContent from "readmin_pages/ReadminSidebarContent";
import READMIN_ROUTES from "READMIN_ROUTES";

export const readminContext = createContext();

const SIDEBAR_COLLAPSED_WIDTH = 25;
const SIDEBAR_EXPANDED_WIDTH = 250;

export default function Readmin() {
  const enforceAuth = useEnforceAuth({ enforceAuthenticated: true });

  let [sidebarOpen, sidebarOpenSet] = useState(false);
  const [sidebarPersistent, sidebarPersistentSet] = useState(false);
  const [sidebarOpenerHovering, sidebarOpenerHoveringSet] = useState(false);
  sidebarOpen ||= sidebarPersistent;
  const windowSize = useWindowSize();
  const sidebarPersistentDefault = windowSize.width > 1000;

  useLayoutEffect(() => {
    sidebarPersistentSet(sidebarPersistentDefault);
  }, [sidebarPersistentDefault]);

  if (enforceAuth.loading) {
    return <PublicForm dense loading />;
  }

  return (
    <readminContext.Provider value={{ sidebarOpen }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          height: "100svh",
          width: "100svw",
          overflow: "hidden",
          alignItems: "stretch",
        }}
      >
        <AppBar style={{ display: "flex", flexFlow: "row wrap", position: "relative", zIndex: 11 }}>
          <ReadminMenuContent />
        </AppBar>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "stretch",
          }}
        >
          <Paper
            square
            elevation={5}
            style={{
              flex: `0 0 ${SIDEBAR_COLLAPSED_WIDTH}px`,
              position: "relative",
              zIndex: 10,
              ...(sidebarOpen && {
                flex: `0 0 ${SIDEBAR_EXPANDED_WIDTH}px`,
                ...(!sidebarPersistent && {
                  marginRight: -(SIDEBAR_EXPANDED_WIDTH - SIDEBAR_COLLAPSED_WIDTH),
                }),
              }),
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "stretch",
              transition: "flex-basis 0.1s, margin 0.1s",
            }}
            onMouseEnter={() => sidebarOpenSet(true)}
            onMouseLeave={() => sidebarOpenSet(false)}
          >
            <Scrollable
              style={{
                flex: "1 1 auto",
              }}
            >
              <div style={{ width: SIDEBAR_EXPANDED_WIDTH, opacity: sidebarOpen ? 1 : 0 }}>
                <List dense disablePadding>
                  <ReadminSidebarContent />
                </List>
              </div>
            </Scrollable>
            <Paper
              square
              style={{
                position: "absolute",
                top: 20,
                right: -20,
                width: 40,
                height: 40,
                borderRadius: "50%",
                fontSize: 16,
                ...(sidebarPersistent && { color: COLOR_SECONDARY }),
              }}
              component={ButtonBase}
              onClick={() => sidebarPersistentSet((p) => !p)}
              onMouseEnter={() => sidebarOpenerHoveringSet(true)}
              onMouseLeave={() => sidebarOpenerHoveringSet(false)}
            >
              {sidebarPersistent || sidebarOpenerHovering ? (
                <Pin fontSize="inherit" />
              ) : (
                <ChevronDoubleRight
                  fontSize="inherit"
                  style={{
                    transform: sidebarOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.1s",
                  }}
                />
              )}
            </Paper>
          </Paper>
          <Scrollable
            style={{
              flex: "1 1 auto",
            }}
          >
            <Routes>
              {Object.entries(READMIN_ROUTES).map(([path, Component]) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
              <Route path="*" element={<ReactPageFallthrough />} />
            </Routes>
          </Scrollable>
        </div>
      </div>
    </readminContext.Provider>
  );
}

const ReadminSidebarItemContext = createContext({ level: -1 });

export function ReadminSidebarItem({ logoUrl, label, href, onClick, children, ...others }) {
  const ref = useRef();
  const [open, openSet] = useState(false);
  const { level: parentLevel, forceOpen: parentForceOpen } = useContext(ReadminSidebarItemContext);

  const level = parentLevel + 1;
  const forceOpen = () => {
    parentForceOpen?.();
    if (children) openSet(true);
  };

  const location = useLocation();
  const hrefLowerCase = href?.toLowerCase();
  const currentPathname = location.pathname.toLowerCase();
  useLayoutEffect(() => {
    if (hrefLowerCase && currentPathname.startsWith(hrefLowerCase)) {
      forceOpen();
    }
  }, [location.pathname]);

  // highlight the item that links to a path potentially being parent of current path.
  let highlighted = false;
  if (href) {
    if (hrefLowerCase === currentPathname) {
      highlighted = true;
    } else if (
      currentPathname.startsWith(hrefLowerCase) &&
      // exclude some special cases
      hrefLowerCase !== "/readmin" && // -> "Dashboard"
      hrefLowerCase !== "/readmin/clubs" && // -> "Admin" -> "Clubs"
      hrefLowerCase !== "/readmin/api_partners" && // -> "Admin" -> "API Partners"
      !matchPath("/readmin/clubs/:clubId", hrefLowerCase) && // -> "(club name)"
      !matchPath("/readmin/api_partners/:apiPartnerId", hrefLowerCase) // -> "(api partner name)"
    ) {
      highlighted = true;
    }
  }

  useEffect(() => {
    // if it's the root-level collapsible item...
    if (level === 0 && children) {
      // and its the only item except "Dashboard", we should expand it so user see all menu items straight away
      const siblingElements = ref.current?.parentElement.querySelectorAll(
        [
          "[data-readmin-sidebar-item-level='0']",
          ':not([data-readmin-sidebar-item-label="Dashboard"])',
          `:not([data-readmin-sidebar-item-label="${encodeURIComponent(label)}"])`,
        ].join(""),
      );
      if (!siblingElements.length) forceOpen();
    }
  }, [level, !!children]);

  return (
    <ReadminSidebarItemContext.Provider
      value={{
        forceOpen,
        level,
      }}
    >
      <ListItemButton
        ref={ref}
        data-readmin-sidebar-item-label={encodeURIComponent(label)}
        data-readmin-sidebar-item-level={level}
        selected={highlighted}
        href={href}
        onClick={() => {
          onClick?.();
          if (children) openSet((o) => !o);
        }}
        {...others}
      >
        <div style={{ width: level * 5 }} />
        <ChevronDown
          style={{
            opacity: children ? 1 : 0,
            transform: open ? "rotate(0deg)" : "rotate(-90deg)",
            transition: "transform 0.1s",
          }}
        />
        {logoUrl ? (
          <div style={{ flex: "1 1 auto" }}>
            <img src={logoUrl} alt={label} style={{ height: "2.5em", width: "100%", objectFit: "contain" }} />
          </div>
        ) : (
          <ListItemText primary={label} />
        )}
      </ListItemButton>
      {!!children && (
        <Collapse in={open} timeout="auto">
          <List dense component="nav" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </ReadminSidebarItemContext.Provider>
  );
}

export function ReadminMenuImageItem({ src, ...others }) {
  return (
    <Button color="inherit" {...others}>
      <img src={src} alt="" style={{ height: "2.5em", width: "auto" }} />
    </Button>
  );
}

export function ReadminMenuItem({ icon, label, ...others }) {
  return (
    <Button color="inherit" {...others}>
      {icon}
      {label}
    </Button>
  );
}

export function ReadminMenuDropdown({ icon, label, alignOnRight = false, children, ...others }) {
  const [anchorEl, anchorElSet] = useState(null);
  const windowSize = useWindowSize();

  return (
    <>
      <Button color="inherit" {...others} onClick={(event) => anchorElSet(event.currentTarget)}>
        {icon}
        {windowSize.width > 640 && <>{label}</>}
        <MenuDown />
      </Button>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: alignOnRight ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: alignOnRight ? "right" : "left" }}
        open={!!anchorEl}
        onClose={() => anchorElSet(null)}
        anchorEl={anchorEl}
        component="div"
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
}

export function ReadminMenuDropdownItem({ icon, label, ...others }) {
  return (
    <MenuItem component="a" {...others}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </MenuItem>
  );
}
