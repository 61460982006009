import { Alert, AlertTitle } from "@mui/material";
import React from "react";

export default function Message({ type = "info", title, content, toolbar }) {
  return (
    <Alert
      variant="outlined"
      severity={
        {
          info: "info",
          success: "success",
          error: "error",
          warning: "warning",
        }[type]
      }
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          gap: 10,
        }}
      >
        {title && <AlertTitle style={{ margin: 0 }}>{title}</AlertTitle>}
        <div>{content}</div>
        {toolbar}
      </div>
    </Alert>
  );
}
