import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LeaguesFormFragment = gql`
  fragment LeaguesFormFragment on League {
    id
    club {
      id
    }
    sport
    name
    age
    gender
    active
    potwEnabled
    videoRecordingPermitted
    sharingDisabled
    anonymisePeople
    pointsForWin
    pointsForDraw
    pointsForLoss
    scoreboardFoulsVisible
    adPreset {
      id
    }
    basketballLeagueSettings {
      id
      pointsForForfeit
    }
  }
`;

export default function LeaguesForm({ leagues }) {
  return (
    <>
      {leagues?.every((league) => !league.id) && (
        <MultiValuesField
          label="Club"
          name="club"
          required
          defaultValues={leagues?.map((league) => league.club)}
          input={<ReadminObjectInput optionsTypename="Club" />}
        />
      )}
      <MultiValuesField
        label="Sport"
        name="sport"
        required
        defaultValues={leagues?.map((league) => league.sport)}
        disabled={!!leagues?.find((league) => league.id)}
        input={<SelectInput options={["Basketball", "Miniball", "Futsal"]} />}
      />
      <MultiValuesField
        label="Name"
        name="name"
        required
        defaultValues={leagues?.map((league) => league.name)}
        input={<TextInput />}
      />
      <MultiValuesField
        label="Age"
        name="age"
        defaultValues={leagues?.map((league) => league.age)}
        input={<SelectInput options={["adult", "child", "teen"]} />}
      />
      <MultiValuesField
        label="Gender"
        name="gender"
        defaultValues={leagues?.map((league) => league.gender)}
        input={<SelectInput options={["female", "male", "mixed"]} />}
      />
      <MultiValuesField
        label="Active"
        name="active"
        defaultValues={leagues?.map((league) => league.active)}
        input={<SwitchInput />}
        helperText={<>League will be available on Scorekeeper.</>}
      />
      <MultiValuesField
        label="Ad Preset"
        name="adPreset"
        defaultValues={leagues?.map((league) => league.adPreset)}
        input={<ReadminObjectInput optionsTypename="AdPreset" />}
      />
      <MultiValuesField
        label="Plays of the Week"
        name="potwEnabled"
        defaultValues={leagues?.map((league) => league.potwEnabled)}
        input={<SwitchInput />}
        helperText={<>If enabled, all games in this league will be eligible to participate in Plays of the Week.</>}
      />
      <FormSection header="Privacy Settings">
        <MultiValuesField
          label="Video Recording Permitted"
          name="videoRecordingPermitted"
          defaultValues={leagues?.map((league) => league.videoRecordingPermitted)}
          input={<SwitchInput />}
          helperText={<>If enabled, video recording will be enabled for games in this league.</>}
        />
        <MultiValuesField
          label="Disable Sharing"
          name="sharingDisabled"
          defaultValues={leagues?.map((league) => league.sharingDisabled)}
          input={<SwitchInput />}
          helperText={<>If enabled, sharing from Player Portal will be disabled for all games in this league.</>}
        />
        <MultiValuesField
          label="Anonymise People"
          name="anonymisePeople"
          defaultValues={leagues?.map((league) => league.anonymisePeople)}
          input={<SwitchInput />}
          helperText={<>If enabled, all names in Player Portal will be hidden.</>}
        />
      </FormSection>
      <FormSection header="Leaderboard Settings">
        <MultiValuesField
          label="Points for Win"
          name="pointsForWin"
          defaultValues={leagues?.map((league) => league.pointsForWin)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a win.</>}
        />
        <MultiValuesField
          label="Points for Draw"
          name="pointsForDraw"
          defaultValues={leagues?.map((league) => league.pointsForDraw)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a draw.</>}
        />
        <MultiValuesField
          label="Points for Loss"
          name="pointsForLoss"
          defaultValues={leagues?.map((league) => league.pointsForLoss)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a loss.</>}
        />
        {leagues?.every((league) => league.basketballLeagueSettings) && (
          <MultiValuesField
            label="Points for Forfeit"
            name="pointsForForfeit"
            defaultValues={leagues?.map((league) => league.basketballLeagueSettings.pointsForForfeit)}
            input={<NumberInput />}
            required
            helperText={<>Number of points awarded for a forfeit win.</>}
          />
        )}
      </FormSection>
      <FormSection header="Live Scoreboard Settings">
        <MultiValuesField
          label="Show Fouls"
          name="scoreboardFoulsVisible"
          defaultValues={leagues?.map((league) => league.scoreboardFoulsVisible)}
          input={<SwitchInput />}
          helperText={<>If enabled, fouls will be shown on the live scoreboard.</>}
        />
      </FormSection>
    </>
  );
}
