import { ApolloClient, ApolloLink, gql } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";

if (["test", "development"].includes(RAILS_ENV)) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

import apolloCache from "./apolloCache";
import { CURRENT_USER_ID, RAILS_ENV } from "./constants";

let _apolloClientInited = false;
export async function initApolloClient() {
  if (_apolloClientInited) return;
  _apolloClientInited = true;
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    setContext(() => {
      const csrfToken = document.querySelector("meta[name=csrf-token]")?.getAttribute("content");
      return {
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      };
    }),
    new RetryLink({
      delay: (count) => {
        return Math.max(10000, count * 1000 * Math.random());
      },
      attempts: (count, operation, error) => {
        if (!error) return false;
        return true;
      },
    }),
    new HttpLink({
      credentials: "same-origin",
    }),
  ]),
  cache: apolloCache,
});

// Hydrate cache so useEnforceAuth has up-to-date data
apolloClient.writeFragment({
  id: "ROOT_QUERY",
  fragment: gql`
    fragment CurrentUserFragment on Query {
      currentUser {
        id
      }
    }
  `,
  data: {
    currentUser: CURRENT_USER_ID && {
      __typename: "User",
      id: CURRENT_USER_ID,
    },
  },
});

export default apolloClient;
