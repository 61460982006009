import { gql } from "@apollo/client";
import DateTime from "controls/DateTime";
import DefinitionTable from "controls/DefinitionTable";
import DialogsToolbar from "controls/DialogsToolbar";
import FileSize from "controls/FileSize";
import FormDialog from "controls/FormDialog";
import useData from "hooks/useData";
import useProgressBar from "hooks/useProgressBar";
import { Information } from "mdi-material-ui";
import React from "react";

import { ReadminCsvExportDeleteDialog } from "./dialogs";
import DownloadReadminCsvExportDialog from "./DownloadReadminCsvExportDialog";
import ReadminCsvExportMessage, { ReadminCsvExportMessageFragment } from "./ReadminCsvExportMessage";

ViewReadminCsvExportDialog.buttonProps = {
  icon: <Information />,
  content: "Show Details",
};

export default function ViewReadminCsvExportDialog({ readminCsvExportId, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query ViewReadminCsvExportDialog($readminCsvExportId: ID!) {
        currentUser {
          id
          readminCsvExport(id: $readminCsvExportId) {
            id
            title
            state
            exportedRows
            reportSize
            createdAt
            ...ReadminCsvExportMessageFragment
          }
        }
      }
      ${ReadminCsvExportMessageFragment}
    `,
    { readminCsvExportId },
    { pollInterval: 1000 },
  );
  useProgressBar(["exporting", "pending"].includes(data?.currentUser?.readminCsvExport?.state));

  return (
    <FormDialog
      onClose={onClose}
      loading={!data}
      header={
        <>
          CSV Export: <b>{data?.currentUser?.readminCsvExport?.title}</b>
        </>
      }
    >
      <ReadminCsvExportMessage
        readminCsvExport={data?.currentUser?.readminCsvExport}
        toolbar={
          <DialogsToolbar
            onSubmit={async () => {
              await dataMeta.refetch();
            }}
            dialogs={[
              <DownloadReadminCsvExportDialog readminCsvExportId={readminCsvExportId} />,
              <ReadminCsvExportDeleteDialog readminCsvExportId={readminCsvExportId} />,
            ]}
          />
        }
      />
      <DefinitionTable
        style={{ margin: "0 -10px" }}
        rows={[
          ["State", data?.currentUser?.readminCsvExport?.state],
          ["Exported Rows", data?.currentUser?.readminCsvExport?.exportedRows],
          ["Report Size", <FileSize value={data?.currentUser?.readminCsvExport?.reportSize} />],
          ["Created at", <DateTime value={data?.currentUser?.readminCsvExport?.createdAt} />],
        ]}
      />
    </FormDialog>
  );
}
