import React, { cloneElement } from "react";

export default function Scrollable({ element = <div />, children, innerElement = <div />, ...others }) {
  return cloneElement(element, {
    ...others,
    style: {
      ...element.props.style,
      ...others.style,
      position: "relative",
    },
    children: cloneElement(innerElement, {
      style: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "auto",
        overflowX: "hidden",
        ...innerElement.props.style,
      },
      children,
    }),
  });
}
