import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import React from "react";
import LiveStreamFbAuthStatus from "readmin_pages/live_streams/LiveStreamFbAuthStatus";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LiveStreamFbOutputsFormFragment = gql`
  fragment LiveStreamFbOutputsFormFragment on LiveStreamFbOutput {
    id
    liveStream {
      id
    }
    title
    description
    fbProfileId
  }
`;

export default function LiveStreamFbOutputsForm({ liveStreamFbOutputs }) {
  const created = liveStreamFbOutputs?.some((liveStreamFbOutput) => liveStreamFbOutput.id);

  const [data] = useData(
    gql`
      query LiveStreamFbOutputsForm($fbProfileIds: [String!]) {
        currentUser {
          id
          liveStreamFbAuth {
            id
            manageFbProfiles
            readFbProfiles(fbProfileIds: $fbProfileIds)
          }
        }
      }
    `,
    { fbProfileIds: liveStreamFbOutputs?.map((liveStreamFbOutput) => liveStreamFbOutput.fbProfileId) },
  );

  return (
    <>
      <MultiValuesField
        label="LiveStream"
        name="liveStream"
        required
        disabled={liveStreamFbOutputs?.some((liveStreamFbOutput) => liveStreamFbOutput.id)}
        defaultValues={liveStreamFbOutputs?.map((liveStreamFbOutput) => liveStreamFbOutput.liveStream)}
        input={<ReadminObjectInput optionsTypename="LiveStream" />}
      />
      <FormSection header="Facebook Account" defaultExpanded>
        <LiveStreamFbAuthStatus />
      </FormSection>
      <MultiValuesField
        label="Publish to Facebook Profile"
        name="fbProfileId"
        required
        disabled={created}
        defaultValues={liveStreamFbOutputs?.map((liveStreamFbOutput) => liveStreamFbOutput.fbProfileId)}
        input={
          <SelectInput
            options={[
              ...(data?.currentUser?.liveStreamFbAuth?.manageFbProfiles || []),
              ...(data?.currentUser?.liveStreamFbAuth?.readFbProfiles || []),
            ].map((fbProfile) => ({
              avatar: fbProfile.picture,
              text: fbProfile.name,
              value: fbProfile.id,
            }))}
          />
        }
      />
      <MultiValuesField
        name="title"
        label="Facebook Post Title"
        required
        input={<TextInput />}
        defaultValues={liveStreamFbOutputs?.map((liveStreamFbOutput) => liveStreamFbOutput.title)}
      />
      <MultiValuesField
        name="description"
        label="Facebook Post Description"
        input={<TextInput multiline minRows={5} />}
        defaultValues={liveStreamFbOutputs?.map((liveStreamFbOutput) => liveStreamFbOutput.description)}
      />
    </>
  );
}
