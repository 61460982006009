import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { intlFormat, parseISO } from "date-fns";
import noop from "helpers/noop";
import useTimeZone from "hooks/useTimeZone";
import { groupBy, uniq } from "lodash-es";
import { Grid as GridIcon, MenuDown, Table as TableIcon } from "mdi-material-ui";
import React, { cloneElement, Fragment, useId, useLayoutEffect, useRef, useState } from "react";

import DateTime from "./DateTime";
import FetchMoreButton from "./FetchMoreButton";
import Message from "./Message";
import VideoThumbnail from "./VideoThumbnail";

export default function DataTable({
  selectable = false,
  selectedKeys = [],
  selectedActions = null,
  onSelectedKeysChange,
  defaultMode = "table",
  columns = [],
  totalPages,
  page,
  onPageChange,
  rows,
  dataMeta,
  fetchMore = dataMeta?.fetchMore,
  rowsCount,
  footer,
  header,
  prependColumns = [],
  prependColumnsRows,
  ...others
}) {
  const [thumbnailSize, thumbnailSizeSet] = useState(300);
  const ref = useRef();
  const [mode, setMode] = useState(defaultMode);
  const timeZone = useTimeZone();

  columns = [...prependColumns, ...columns];
  rows = rows
    // prependColumns
    ?.map((row, rowIndex) => ({
      ...row,
      cells: [
        ...prependColumns.map((prependColumn, prependColumnIndex) => {
          const prependColumnRow = prependColumnsRows[rowIndex];
          return prependColumnRow[prependColumnIndex];
        }),
        ...row.cells,
      ],
    }))
    // datetime
    .map(
      ({
        datetime = null,
        groupBy = datetime &&
          intlFormat(parseISO(datetime), {
            dateStyle: "full",
            timeZone,
          }),
        groupLabel = (datetime && <DateTime value={datetime} variant="date" />) || groupBy,
        ...row
      }) => ({
        ...row,
        groupBy,
        groupLabel,
      }),
    );

  if (rows?.find((r) => r.thumbnailUrl || r.videoUrl || r.thumbnailLoading || r.vidoeLoading))
    rows = rows.map(({ thumbnailUrl, thumbnailLoading, vidoeLoading, videoUrl, ...row }) => ({
      ...row,
      thumbnailUrl,
      videoUrl,
      thumbnail: (
        <VideoThumbnail loading={thumbnailLoading || vidoeLoading} videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />
      ),
    }));

  columns = columns
    .map((column) => (column?.constructor === Object ? column : { label: column }))
    .map((column, index) => ({ ...column, index }));
  const visibleColumns = columns.filter((column) => !column.hidden);

  useLayoutEffect(() => {
    if (page > 0 && page >= totalPages) onPageChange(0);
  }, [page, totalPages]);

  const groups = rows && Object.values(groupBy(rows, (r) => r.groupBy));

  const lastClickedSelectCheckboxRef = useRef(null);
  const lastClickedShouldCheckRef = useRef(false);
  const handleSelectCheckboxClick = (event, clickedKeys) => {
    const allCheckboxes = [...ref.current.querySelectorAll("[data-datatable-select-checkbox]")];
    const lastClickedIndex = allCheckboxes.indexOf(lastClickedSelectCheckboxRef.current);
    const clickedIndex = [...allCheckboxes].indexOf(event.currentTarget);

    event.preventDefault();
    // by default, toggle current clicked checkbox to the opposite state
    let toggleKeys = clickedKeys;
    let shouldCheck = !clickedKeys.every((k) => selectedKeys.includes(k));

    // if shift key is pressed, toggle all checkboxes between last clicked and current clicked
    // to the memorized state
    if (event.shiftKey && lastClickedIndex >= 0 && clickedIndex >= 0) {
      const toggleCheckboxes = allCheckboxes.slice(
        Math.min(lastClickedIndex, clickedIndex),
        Math.max(lastClickedIndex, clickedIndex) + 1,
      );
      toggleKeys = toggleCheckboxes
        .map((checkbox) => checkbox.dataset.datatableSelectCheckboxKey)
        .filter((k) => k !== undefined);
      shouldCheck = lastClickedShouldCheckRef.current;
    }

    onSelectedKeysChange(
      shouldCheck ? uniq([...selectedKeys, ...toggleKeys]) : selectedKeys.filter((k) => !toggleKeys.includes(k)),
    );

    lastClickedSelectCheckboxRef.current = event.currentTarget;
    lastClickedShouldCheckRef.current = shouldCheck;
  };

  return (
    <TableContainer
      ref={ref}
      data-datatable
      component={Paper}
      variant="outlined"
      {...others}
      style={{
        width: "auto", // overwrite MUI width: 100%
        display: "flex",
        flexFlow: "column nowrap",
        gap: 10,
        ...others.style,
      }}
    >
      <Table size="small">
        <TableHead>
          {header && (
            <TableRow>
              <TableCell colSpan={9999}>{header}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell padding="none" colSpan={9999}>
              <div style={{ display: "flex", flexFlow: "row wrap", padding: 5, gap: 5, alignItems: "center" }}>
                {selectable && (
                  <>
                    <Button disabled>Selecting {selectedKeys.length} rows</Button>
                    {selectedActions}
                  </>
                )}
                <div style={{ flex: "1 0 auto" }} />
                {mode === "grid" && (
                  <>
                    <div>Size:</div>
                    <Slider
                      size="small"
                      style={{ width: 100, margin: "0 5px" }}
                      value={thumbnailSize}
                      min={200}
                      max={600}
                      onChange={(event, value) => thumbnailSizeSet(value)}
                    />
                  </>
                )}

                <ButtonGroup style={{ backgroundColor: "#fff" }}>
                  <Button color={mode === "table" ? "secondary" : undefined} onClick={() => setMode("table")}>
                    <TableIcon />
                  </Button>
                  <Button color={mode === "grid" ? "secondary" : undefined} onClick={() => setMode("grid")}>
                    <GridIcon />
                  </Button>
                </ButtonGroup>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            {selectable && (
              <TableCell padding="checkbox">
                <Checkbox
                  data-datatable-select-checkbox
                  onClick={(event) => handleSelectCheckboxClick(event, rows?.map((row) => row.key) || [])}
                  checked={!!rows?.length && rows?.every((row) => selectedKeys.includes(row.key))}
                  indeterminate={
                    !!rows?.find((row) => selectedKeys.includes(row.key)) &&
                    !!rows?.find((row) => !selectedKeys.includes(row.key))
                  }
                />
              </TableCell>
            )}
            {visibleColumns.map((column) => (
              <TableCell key={column.index}>{mode === "table" && column.label}</TableCell>
            ))}
            <TableCell
              style={{
                position: "sticky",
                right: 0,
              }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows && (
            <TableRow>
              <TableCell></TableCell>
              {visibleColumns.map((column) => (
                <TableCell key={column.index}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          )}
          {groups?.map((group) => (
            <Fragment key={group[0].groupBy}>
              {group[0].groupLabel && (
                <TableRow selected={group.every((row) => selectedKeys.includes(row.key))}>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        data-datatable-select-checkbox
                        onClick={(event) =>
                          handleSelectCheckboxClick(
                            event,
                            group.map((row) => row.key),
                          )
                        }
                        checked={!group.find((row) => !selectedKeys.includes(row.key))}
                        indeterminate={
                          !!group.find((row) => selectedKeys.includes(row.key)) &&
                          !!group.find((row) => !selectedKeys.includes(row.key))
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell variant="head" colSpan={9999}>
                    {group[0].groupLabel}
                  </TableCell>
                </TableRow>
              )}
              {mode === "grid" && (
                <TableRow selected={group.every((row) => selectedKeys.includes(row.key))}>
                  <TableCell colSpan={9999} padding="none">
                    <div
                      style={{
                        padding: 10,
                        display: "grid",
                        gridTemplateColumns: `repeat(auto-fill, minmax(${thumbnailSize}px, 1fr))`,
                        gridGap: 10,
                      }}
                    >
                      {group
                        .map(({ cells, ...others }) => ({
                          ...others,
                          visibleColumns: visibleColumns.map((column, columnIndex) => ({
                            ...column,
                            cell: cells[columnIndex],
                          })),
                        }))
                        .map(
                          ({
                            key,
                            visibleColumns,
                            defaultAction,
                            labels,
                            moreActions = [],
                            thumbnail,
                            actionsDropdown = (
                              <DataTableActionsDropdown defaultAction={defaultAction} moreActions={moreActions} />
                            ),
                          }) => (
                            <Card
                              elevation={3}
                              key={key}
                              style={{
                                position: "relative",
                                display: "flex",
                                flexFlow: "column nowrap",
                                ...(selectedKeys.includes(key) && { backgroundColor: "#eee" }),
                              }}
                            >
                              {thumbnail &&
                                cloneElement(thumbnail, {
                                  style: {
                                    ...thumbnail.props.style,
                                    overflow: "hidden",
                                    aspectRatio: "16/9",
                                    width: "100%",
                                  },
                                })}
                              <div
                                style={{
                                  flex: "1 0 auto",
                                  display: "flex",
                                  flexFlow: "column nowrap",
                                  gap: 5,
                                  padding: 10,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    alignItems: "center",
                                    gap: 5,
                                  }}
                                >
                                  {labels}
                                </div>
                                {visibleColumns.map(({ label, cell }) => (
                                  <div key={label} style={{ lineHeight: 1 }}>
                                    <small style={{ opacity: 0.9 }}>{label}</small>:
                                    <br />
                                    <div style={{ marginLeft: ".5em", lineHeight: 1.2 }}>{cell}</div>
                                  </div>
                                ))}
                              </div>
                              <CardActions>
                                {actionsDropdown}
                                {selectable && (
                                  <div style={{ flex: "1 0 auto", display: "flex", justifyContent: "flex-end" }}>
                                    <Checkbox
                                      data-datatable-select-checkbox
                                      data-datatable-select-checkbox-key={key}
                                      onClick={(event) => handleSelectCheckboxClick(event, [key])}
                                      checked={selectedKeys.includes(key)}
                                    />
                                  </div>
                                )}
                              </CardActions>
                            </Card>
                          ),
                        )}
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {mode === "table" &&
                group.map(
                  ({
                    key,
                    cells,
                    labels,
                    defaultAction,
                    moreActions = [],
                    thumbnail,
                    actionsDropdown = (
                      <DataTableActionsDropdown defaultAction={defaultAction} moreActions={moreActions} />
                    ),
                  }) => (
                    <TableRow key={key} selected={selectedKeys.includes(key)}>
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            data-datatable-select-checkbox
                            data-datatable-select-checkbox-key={key}
                            onClick={(event) => handleSelectCheckboxClick(event, [key])}
                            checked={selectedKeys.includes(key)}
                          />
                        </TableCell>
                      )}
                      {visibleColumns.map((column) => (
                        <TableCell key={column.index}>{cells[column.index]}</TableCell>
                      ))}
                      <TableCell
                        padding="none"
                        selected
                        style={{
                          position: "sticky",
                          right: 0,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 5,
                            padding: 5,
                          }}
                        >
                          {labels}
                          {actionsDropdown}
                          {thumbnail &&
                            cloneElement(thumbnail, {
                              style: {
                                ...thumbnail.props.style,
                                overflow: "hidden",
                                aspectRatio: "16/9",
                                height: "3em",
                              },
                            })}
                        </div>
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </Fragment>
          ))}
          {rows?.length === 0 && (
            <TableRow>
              <TableCell colSpan={9999}>
                <Message content={<>No data to display</>} />
              </TableCell>
            </TableRow>
          )}
          {rows?.length > 0 && (
            <TableRow>
              <TableCell colSpan={9999}>
                <Message
                  content={
                    <>
                      Tips: you can hit <b>Enter</b> to select the first record.
                    </>
                  }
                />
              </TableCell>
            </TableRow>
          )}
          {fetchMore && (
            <TableRow>
              <TableCell colSpan={9999}>
                <FetchMoreButton rows={rows} rowsCount={rowsCount} fetchMore={fetchMore} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {footer && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={9999}>{footer}</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export function DataTableSelectedAction({
  content,
  icon,
  hidden = false,
  disabled = false,
  href = "#",
  onClick,
  target,
}) {
  return (
    !hidden && (
      <Button variant="outlined" href="a" startIcon={icon} {...{ disabled, href, onClick, target }}>
        {content}
      </Button>
    )
  );
}

export function DataTableActionsDropdown({ defaultAction, moreActions = [], ...others }) {
  const [anchorEl, anchorElSet] = useState(null);
  const id = useId();
  const dropdownButtonId = `${id}-dropdown-button`;
  const menuId = `${id}-menu`;

  if (defaultAction?.hidden) defaultAction = null;
  moreActions = moreActions.filter(({ hidden }) => !hidden);

  const buttons = [
    defaultAction && (
      <Button
        size="small"
        onClick={defaultAction.onClick}
        disabled={defaultAction.disabled}
        href={defaultAction.href}
        target={defaultAction.target}
        startIcon={defaultAction.icon}
      >
        {defaultAction.content}
      </Button>
    ),
    !!moreActions.length && (
      <Button
        component="a"
        href="#"
        size="small"
        onClick={(event) => anchorElSet(event.currentTarget)}
        data-more-actions-button
        id={dropdownButtonId}
        aria-controls={anchorEl ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
      >
        {!defaultAction && "More..."}
        <MenuDown />
      </Button>
    ),
  ].filter(Boolean);

  if (!buttons.length) return null;

  return (
    <>
      <ButtonGroup variant="contained" {...others} style={{ ...others.style }} data-more-actions>
        {buttons.map((button, buttonIndex) => cloneElement(button, { key: buttonIndex }))}
      </ButtonGroup>
      <Menu
        id={menuId}
        MenuListProps={{ "aria-labelledby": dropdownButtonId, "data-more-actions-list": true, component: "div" }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => anchorElSet(null)}
      >
        {moreActions.map(({ content, icon, disabled, onClick = noop, href = "#" }) => (
          <MenuItem
            key={content}
            data-more-actions-item
            disabled={disabled}
            href={href}
            component={"a"}
            onClick={(event) => {
              onClick(event);
              anchorElSet(null);
            }}
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={content} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
