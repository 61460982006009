import { gql } from "@apollo/client";
import LoadingFormDialog from "controls/LoadingFormDialog";
import downloadBlob from "helpers/downloadBlob";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Download } from "mdi-material-ui";
import React from "react";

DownloadReadminCsvExportDialog.useButtonProps = ({ readminCsvExportId }) => {
  const [data] = useData(
    gql`
      query DownloadReadminCsvExportDialog($readminCsvExportId: ID!) {
        currentUser {
          id
          readminCsvExport(id: $readminCsvExportId) {
            id
            state
          }
        }
      }
    `,
    { readminCsvExportId },
  );
  return {
    icon: <Download />,
    content: "Download",
    disabled: data?.currentUser?.readminCsvExport?.state !== "completed",
  };
};

export default function DownloadReadminCsvExportDialog({ readminCsvExportId, onSubmit, onClose }) {
  const readminCsvExportDownload = useActionFragment("readminCsvExportDownload", "csvStr filename");
  return (
    <LoadingFormDialog
      onClose={onClose}
      onSubmit={async () => {
        const result = await readminCsvExportDownload({
          input: {
            readminCsvExportId,
          },
        });
        const { csvStr, filename } = result.readminCsvExportDownload;
        const blob = new Blob([csvStr], {
          type: "text/plain;charset=utf-8",
        });
        await downloadBlob(blob, filename);
        await onSubmit?.();
      }}
    />
  );
}
