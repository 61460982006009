import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import useData from "hooks/useData";
import { Check } from "mdi-material-ui";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import ReadminObjectsDialogToolbar from "./ReadminObjectsDialogToolbar";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "./ReadminObjectsTable";
import useReadminObjectTypedef from "./useReadminObjectTypedef";

export default function SelectReadminObjectDialog({
  selectedReadminObjects = [],
  options,
  optionsFilters = {},
  optionsTypename,
  onSubmit,
  onClose,
  ...others
}) {
  if (!optionsTypename) throw new Error("optionsTypename is required");
  const [limit, limitSet] = useState(10);
  const { clubId, apiPartnerId } = useParams();

  const optionsTypedef = useReadminObjectTypedef(optionsTypename, { loadFilterBar: true });

  const [rowsData, rowsDataMeta] = useData(
    gql`
      query SelectReadminObjectDialog(
        $clubId: ID
        $apiPartnerId: ID
        $typename: String!
        $optionsIds: [ID!]
        $filterBar: ReadminFilterBar
        $optionsFilters: [ReadminOptionsFilter!]!
        $limit: Int!
        $offset: Int
      ) {
        readminObjectsCount(
          clubId: $clubId
          apiPartnerId: $apiPartnerId
          typename: $typename
          ids: $optionsIds
          filterBar: $filterBar
          optionsFilters: $optionsFilters
        )
        readminObjects(
          clubId: $clubId
          apiPartnerId: $apiPartnerId
          typename: $typename
          ids: $optionsIds
          filterBar: $filterBar
          optionsFilters: $optionsFilters
          limit: $limit
          offset: $offset
        ) {
          id
          ...ReadminObjectsTableFragment
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      clubId,
      apiPartnerId,
      typename: optionsTypename,
      filterBar: optionsTypedef?.filterBarVariables,
      optionsIds: options?.map((option) => option.id),
      limit,
      optionsFilters: Object.entries(optionsFilters).map(([name, value]) => ({
        name,
        value,
      })),
    },
    {
      skip: !optionsTypedef,
    },
  );

  return (
    <FormDialog maxWidth="xl" header="Select..." onClose={onClose} {...others}>
      <ReadminObjectsDialogToolbar
        typename={optionsTypename}
        onSubmit={async (result) => {
          if (result?.id && result?.__typename === optionsTypename) {
            await onSubmit?.(result);
            onClose?.();
            return;
          }
          if (rowsData) rowsDataMeta.refetch();
        }}
      />
      <FiltersBar>
        {optionsTypedef?.filterBarContent}
        <PageSizeFilter value={limit} onChange={limitSet} />
      </FiltersBar>
      <ReadminObjectsTable
        style={{ margin: "0 -10px" }}
        rows={rowsData?.readminObjects}
        rowsCount={rowsData?.readminObjectsCount}
        typename={optionsTypename}
        dataMeta={rowsDataMeta}
        getDefaultAction={(row) => ({
          content: "Select",
          icon: <Check />,
          onClick: async () => {
            await onSubmit?.(row);
            onClose?.();
          },
          ...(selectedReadminObjects.find((o) => o.id === row.id && o.__typename === row.__typename) && {
            content: "Selected",
            disabled: true,
          }),
        })}
      />
    </FormDialog>
  );
}
