import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { times } from "lodash-es";
import React, { cloneElement } from "react";

import VideoThumbnail from "./VideoThumbnail";

export default function DefinitionTable({
  rows,
  header,
  toolbar,
  labels,
  thumbnailUrl,
  videoUrl,
  thumbnail = (thumbnailUrl || videoUrl) && <VideoThumbnail videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />,
  description,
  footers = [],
  ...others
}) {
  return (
    <TableContainer component={Paper} variant="outlined" {...others} style={{ width: "auto", ...others.style }}>
      <Table size="small">
        {(header || toolbar || labels || thumbnailUrl || videoUrl) && (
          <TableHead>
            <TableRow>
              <TableCell colSpan="99">
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    alignItems: "center",
                    gap: 5,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexFlow: "column wrap", gap: 10 }}>
                    <div style={{ fontSize: "1.2em", lineHeight: "2" }}>{header}</div>
                    <div style={{ display: "flex", flexFlow: "row wrap", gap: 3, alignItems: "stretch" }}>{labels}</div>
                    {toolbar}
                  </div>
                  {thumbnail &&
                    cloneElement(thumbnail, {
                      style: { height: "5em", aspectRatio: "16/9", ...thumbnail.props.style },
                    })}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {description && (
            <TableRow>
              <TableCell colSpan="99">{description}</TableCell>
            </TableRow>
          )}
          {!rows &&
            times(5).map((i) => (
              <TableRow key={i}>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            ))}
          {rows
            ?.map((row) =>
              row.constructor === Array
                ? {
                    label: row[0],
                    cell: row[1],
                    description: row[2],
                    ...row[3],
                  }
                : row,
            )
            .map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell variant="head" style={{ width: "20%" }}>
                  {row.label}
                </TableCell>
                <TableCell>{row.cell}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
        </TableBody>
        {footers.map((footer, footerIndex) => (
          <TableFooter key={footerIndex}>
            <TableRow>
              <TableCell colSpan="99" padding="none">
                {footer}
              </TableCell>
            </TableRow>
          </TableFooter>
        ))}
      </Table>
    </TableContainer>
  );
}
