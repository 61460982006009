import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import useData from "hooks/useData";
import { Information } from "mdi-material-ui";
import React from "react";

import ReadminObjectAttributesTable, { ReadminObjectAttributesTableFragment } from "./ReadminObjectAttributesTable";

ReadminObjectViewDetailsDialog.buttonProps = {
  icon: <Information />,
  content: "Show Details",
};

export default function ReadminObjectViewDetailsDialog({ id, typename, onClose }) {
  const [data] = useData(
    gql`
      query ReadminObjectViewDetailsDialog($id: ID!, $typename: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          readminName
          readminUrl
          ...ReadminObjectAttributesTableFragment
        }
      }
      ${ReadminObjectAttributesTableFragment}
    `,
    { id, typename },
  );
  return (
    <FormDialog onClose={onClose}>
      <ReadminObjectAttributesTable
        style={{
          margin: -10, // negates padding from FormDialog
          border: "none", // remove border from DefinitionTable
        }}
        showMedia
        showheader
        showLabels
        showDialogsToolbar
        showLink
        readminObject={data?.readminObject}
      />
    </FormDialog>
  );
}
