import { CssBaseline, ThemeProvider } from "@mui/material";
import useDOMEvent from "hooks/useDOMEvent";
import LiveScorer from "public_pages/LiveScorer";
import LiveStreamWidget from "public_pages/LiveStreamWidget";
import LoginPage from "public_pages/LoginPage";
import LogoutPage from "public_pages/LogoutPage";
import OriginalVideoFormPage from "public_pages/OriginalVideoFormPage";
import RequestResetPasswordPage from "public_pages/RequestResetPasswordPage";
import ResetPasswordPage from "public_pages/ResetPasswordPage";
import RootPage from "public_pages/RootPage";
import TeamRegistrationFormPage from "public_pages/TeamRegistrationFormPage";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import ReactPageFallthrough from "ReactPageFallthrough";
import Readmin from "Readmin";

import { initApolloClient } from "./helpers/apolloClient";
import { timeZoneContext } from "./helpers/contexts";
import muiTheme from "./helpers/muiTheme";

export default function ReactPage() {
  const [timeZone, timeZoneSet] = useState(null);
  const [inited, initedSet] = useState(false);

  useEffect(() => {
    Promise.resolve().then(async () => {
      await initApolloClient();
      initedSet(true);
    });
  }, []);

  if (!inited) return null;

  return (
    <timeZoneContext.Provider value={{ timeZone, timeZoneSet }}>
      <CssBaseline />
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RootPage />} />
            <Route path="/team_registrations/:token" element={<TeamRegistrationFormPage />} />
            <Route path="/live_scorer/:token" element={<LiveScorer />} />
            <Route path="/live_stream_widget/:uuid" element={<LiveStreamWidget />} />
            <Route path="/original_videos/:uuid" element={<OriginalVideoFormPage />} />
            <Route path="/passwords/edit" element={<ResetPasswordPage />} />
            <Route path="/readmin/login" element={<LoginPage />} />
            <Route path="/readmin/logout" element={<LogoutPage />} />
            <Route path="/readmin/reset-password" element={<RequestResetPasswordPage />} />
            <Route path="/readmin/*" element={<Readmin />} />
            <Route path="*" element={<ReactPageFallthrough />} />
          </Routes>
          <LinkClickEventListener />
        </BrowserRouter>
      </ThemeProvider>
    </timeZoneContext.Provider>
  );
}

function LinkClickEventListener() {
  const navigate = useNavigate();

  useDOMEvent(
    "click",
    (event) => {
      const a = event.target.closest("a[href]");
      if (!a) return;
      let href = a.getAttribute("href");
      const target = a.getAttribute("target");

      if (target && target !== "_self") return;
      if (href === "#") return event.preventDefault();
      const url = new URL(href, window.location.href);
      if (url.origin === window.location.origin) href = url.pathname + url.search;
      if (!href.startsWith("/")) return;
      if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
      if (a.dataset?.reactRouterDisabled) return;

      event.preventDefault();
      navigate(href);
    },
    [],
    () => document,
    { capture: true },
  );

  return null;
}
