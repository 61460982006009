import React, { useLayoutEffect } from "react";

export default function PageTitle({ title, platformTitle = "Glory League" }) {
  title = title ? `${title} | ${platformTitle}` : platformTitle;

  useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return null;
}

export function ReadminPageTitle({ title }) {
  return <PageTitle title={title} platformTitle="Glory League Admin" />;
}
