import { gql } from "@apollo/client";
import DefinitionTable from "controls/DefinitionTable";
import { FONT_FAMILY_ROBOTO_MONO } from "helpers/fonts";
import useData from "hooks/useData";
import { uniq } from "lodash-es";
import React from "react";

export default function VenueServerStatusTab({ venueServerId }) {
  const [data] = useData(
    gql`
      query StatusTab($venueServerId: ID!) {
        venueServer(id: $venueServerId) {
          id
          statusEntries
        }
      }
    `,
    { venueServerId },
  );

  const statusEntries = data?.venueServer.statusEntries ?? [];

  return (
    <>
      {uniq(statusEntries.map(([category]) => category)).map((category) => (
        <DefinitionTable
          key={category}
          header={category}
          rows={statusEntries
            .filter(([c]) => c === category)
            .map(([, key, value]) => [
              key,
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  fontFamily: FONT_FAMILY_ROBOTO_MONO,
                }}
              >
                {value}
              </div>,
            ])}
        />
      ))}
    </>
  );
}
