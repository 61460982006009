import { gql } from "@apollo/client";
import Field from "controls/Field";
import Message from "controls/Message";
import { formatInTimeZone } from "date-fns-tz";
import useData from "hooks/useData";
import useTimeZone from "hooks/useTimeZone";
import DateTimeInput from "inputs/DateTimeInput";
import NumberInput from "inputs/NumberInput";
import TimeInput from "inputs/TimeInput";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";

const ISO_DATE_FORMAT = "yyyy-MM-dd";

export default function GameForm({ game = {} }) {
  const [playedAt, playedAtSet] = useState(game.playedAt);
  const [court, courtSet] = useState(game.court);
  const [league, leagueSet] = useState(game.league);
  const timeZone = useTimeZone();
  const date = playedAt && formatInTimeZone(playedAt, timeZone, ISO_DATE_FORMAT);
  const [data] = useData(
    gql`
      query GameForm($courtId: ID, $dateRange: DateRange) {
        court(id: $courtId) {
          id
          recordings(range: $dateRange) {
            id
            ...ReadminObjectsTableFragment
          }
          venue {
            id
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      courtId: court?.id,
      dateRange: date && {
        from: date,
        to: date,
      },
    },
  );

  return (
    <>
      <Message
        type="info"
        content={
          <>
            Use this tool to add a game that was not scored using any scoring app. This will make the game video
            available to existing team members but without any stats.
          </>
        }
      />
      <Field
        name="court"
        label="Court"
        input={<ReadminObjectInput optionsTypename="Court" />}
        required
        defaultValue={game.court}
        onChange={courtSet}
      />
      <Field
        name="playedAt"
        label="Start time"
        input={<DateTimeInput />}
        defaultValue={playedAt}
        onChange={playedAtSet}
        required
      />
      <Field
        name="completedAt"
        label="End time"
        input={<TimeInput date={date} />}
        defaultValue={game.completedAt}
        required
      />
      <Field
        name="league"
        label="League"
        input={<ReadminObjectInput optionsTypename="League" />}
        required
        defaultValue={league}
        onChange={leagueSet}
      />
      <Field
        name="home"
        label="Home Team"
        disabled={!league}
        input={<ReadminObjectInput optionsTypename="Team" optionsFilters={{ in_league_id: league?.id }} />}
        required
        defaultValue={game.home}
      />
      <Field
        name="away"
        label="Away Team"
        disabled={!league}
        input={<ReadminObjectInput optionsTypename="Team" optionsFilters={{ in_league_id: league?.id }} />}
        required
        defaultValue={game.away}
      />
      <Field name="homeScore" label="Home Team Score" defaultValue={0} required input={<NumberInput min={0} />} />
      <Field name="awayScore" label="Away Team Score" defaultValue={0} required input={<NumberInput min={0} />} />
      <ReadminObjectsTable
        ignoreAssociationObjects={[data?.court, data?.court?.venue]}
        header="Recordings"
        rows={data?.court?.recordings}
      />
    </>
  );
}
