import { gql } from "@apollo/client";
import DateRangeFilter, { ALL_DATE_RANGE_VALUES } from "filters/DateRangeFilter";
import ScopesFilter from "filters/ScopesFilter";
import SearchTermFilter from "filters/SearchTermFilter";
import SelectFilter from "filters/SelectFilter";
import useData from "hooks/useData";
import useRouteState from "hooks/useRouteState";
import useSearchTerm from "hooks/useSearchTerm";
import useTimeZone from "hooks/useTimeZone";
import React from "react";

export default function useReadminObjectTypedef(typename, { loadAssociations = false, loadFilterBar = false } = {}) {
  const timeZone = useTimeZone();

  const [data] = useData(
    gql`
      query useReadminObjectTypedef($typename: String!) {
        readminObjectTypedef(typename: $typename) {
          id
        }
      }
    `,
    { typename },
    { skip: !typename, fetchPolicy: "cache-first" },
  );

  const [associationsData] = useData(
    gql`
      query useReadminObjectTypedef_associations($typename: String!) {
        readminObjectTypedef(typename: $typename) {
          id
          readminAssociations {
            name
            typename
          }
        }
      }
    `,
    { typename },
    { skip: !typename || !loadAssociations, fetchPolicy: "cache-first" },
  );

  const [filterBarData] = useData(
    gql`
      query useReadminObjectTypedef_filterBar($typename: String!) {
        readminObjectTypedef(typename: $typename) {
          id
          readminFilters {
            name
            options {
              label
              value
            }
            required
            defaultValue
          }
          readminScopes
          readminOrderOptions
          searchEnabled
          dateRangeFilterEnabled
          dateRangeFilterFuture
          dateRangeFilterDefault
        }
      }
    `,
    { typename },
    { skip: !typename || !loadFilterBar },
  );

  let {
    readminScopes = [],
    readminOrderOptions = [],
    readminFilters = [],
    searchEnabled = false,
    dateRangeFilterEnabled = false,
    dateRangeFilterFuture = false,
    dateRangeFilterDefault = null,
  } = filterBarData?.readminObjectTypedef || {};

  dateRangeFilterDefault = ALL_DATE_RANGE_VALUES[dateRangeFilterDefault];

  const [searchTerm, searchTermSet, searchTermDebounced] = useSearchTerm();
  const [filtersHash, filtersHashSet] = useRouteState("filtersHash", {});
  const [scopeFilter, scopeFilterSet] = useRouteState("scopeFilter", null);
  const [orderOption, orderOptionSet] = useRouteState("orderOption", null);
  const [dateRange, dateRangeSet] = useRouteState("dateRange", dateRangeFilterDefault);

  const filters = readminFilters.map((filter) => ({
    ...filter,
    value: Object.keys(filtersHash).includes(filter.name) ? filtersHash[filter.name] : filter.defaultValue,
    onChange: (value) =>
      filtersHashSet((filtersHash) => {
        const filtersHashNew = { ...filtersHash };
        if (value === filter.defaultValue) delete filtersHashNew[filter.name];
        else filtersHashNew[filter.name] = value;
        return filtersHashNew;
      }),
  }));

  const associations = associationsData?.readminObjectTypedef?.readminAssociations;

  const filterBarVariables = {
    ...(searchEnabled && {
      searchTerm: searchTermDebounced,
    }),
    ...(dateRangeFilterEnabled && {
      dateRange,
      timeZone,
    }),
    filters: filters
      ?.filter((filter) => filter.value !== null)
      .map((filter) => ({
        name: filter.name,
        value: filter.value,
      })),
    scopeFilter,
    orderOption,
  };

  const filterBarContent = (
    <>
      {readminScopes.length > 0 && (
        <ScopesFilter value={scopeFilter} onChange={scopeFilterSet} scopes={readminScopes.map((scope) => scope)} />
      )}
      {filters?.map((filter) => (
        <SelectFilter
          key={filter.name}
          label={filter.name}
          value={filter.value}
          options={filter.options.map((option) => ({ text: option.label, value: option.value }))}
          required={filter.required}
          onChange={filter.onChange}
        />
      ))}
      {dateRangeFilterEnabled && (
        <DateRangeFilter value={dateRange} onChange={dateRangeSet} future={dateRangeFilterFuture} />
      )}
      {searchEnabled && <SearchTermFilter value={searchTerm} onChange={searchTermSet} />}
      {readminOrderOptions.length > 0 && (
        <SelectFilter
          label="Sort By"
          blankString="(default)"
          value={orderOption}
          options={readminOrderOptions}
          onChange={orderOptionSet}
        />
      )}
    </>
  );

  const readminObjectTypedef = {
    filterBarContent,
    filterBarVariables,
    associations,
  };

  return data?.readminObjectTypedef && readminObjectTypedef;
}
