import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

PersonAddSupporterDialog.buttonProps = {
  icon: <Plus />,
  content: "Add Supporter",
};

export default function PersonAddSupporterDialog({ personId, onSubmit, onClose }) {
  const [person, personSet] = useState(personId && { id: personId, __typename: "Person" });
  const addPersonSupporter = useActionFragment("addPersonSupporter", "personSupporter { id }");

  return (
    <FormDialog
      header="Add Supporter"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addPersonSupporter({
          input: formData,
        });
        await onSubmit?.(result.addPersonSupporter.personSupporter);
      }}
    >
      <Field
        name="person"
        label="Person"
        value={person}
        onChange={personSet}
        required
        input={<ReadminObjectInput optionsTypename="Person" />}
      />
      <Field
        name="supporter"
        label="Supporter"
        required
        disabled={!person}
        input={
          <ReadminObjectInput
            optionsTypename="Person"
            optionsFilters={{
              possible_supporter_for_person_id: person?.id,
            }}
          />
        }
        helperText="Selected supporter will be able to view all games of the person"
      />
    </FormDialog>
  );
}
