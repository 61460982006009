import "core-js/stable";
import "regenerator-runtime/runtime";
import "styles/browser_upgrade.css";
import "styles/recaptcha.css";
import "styles/ngrogressfix.css";
import "styles/progressbar-spinner.css";
import "graphiql/graphiql.css";
import "styles/stylefix.css";

import railsUjs from "@rails/ujs";
import React from "react";
import { createRoot } from "react-dom/client";

import ReactPage from "../ReactPage";

railsUjs.start();

document.addEventListener("DOMContentLoaded", (event) => {
  const body = event.target;
  for (const element of body.querySelectorAll("[data-react-page-root]")) {
    const root = element._reactRoot || createRoot(element);
    root.render(<ReactPage />);
    element._reactRoot = root;
  }
});

window.GL_JS_PACK_LOADED = true;
