import { TextField } from "@mui/material";
import React from "react";

export default function SearchTermFilter({ onChange, label = "Search...", helperText, ...others }) {
  return (
    <TextField
      autoFocus
      size="small"
      label={label}
      onChange={(event) => onChange(event.target.value)}
      onKeyPress={(event) => {
        if (event.code === "Enter") {
          const table = event.currentTarget.closest("*:has(table)")?.querySelector("table");
          const button = table?.querySelector(
            "[data-more-actions] .ui.button:not(.disabled), [data-more-actions] .MuiButton-root",
          );
          button?.click();
        }
      }}
      helperText={helperText}
      data-search-term-filter
      type="search"
      style={{ flex: "1 1 auto" }}
      {...others}
    />
  );
}
