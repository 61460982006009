import { gql } from "@apollo/client";
import { Button, Modal, Paper } from "@mui/material";
import HorizontalView from "controls/HorizontalView";
import useAction from "hooks/useAction";
import useData from "hooks/useData";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

const PERIODS = ["Q1", "Q2", "Q3", "Q4", "OT", "H1", "H2"];

export default function LiveScorer() {
  const { token } = useParams();
  const ref = useRef();
  const [data] = useData(
    gql`
      query LiveScorer($token: String!) {
        liveScorer(token: $token) {
          id
          team1Score
          team2Score
          team1Display
          team2Display
          period
          overlaySvgUrl
        }
      }
    `,
    { token },
  );

  const updateLiveScorer = useAction(
    gql`
      mutation LiveScorer($input: UpdateLiveScorerInput!) {
        updateLiveScorer(input: $input) {
          liveScorer {
            id
            team1Score
            team2Score
            team1Display
            team2Display
            period
            overlaySvgUrl
          }
        }
      }
    `,
    ({ input: { id, ...attributes } }) => ({
      optimisticResponse: {
        updateLiveScorer: {
          __typename: "UpdateLiveScorerPayload",
          liveScorer: {
            ...(data?.liveScorer || {}),
            ...attributes,
          },
        },
      },
    }),
  );
  const [periodSelecting, periodSelectingSet] = useState(false);

  return (
    <HorizontalView>
      <div
        style={{
          height: "100%",
          background: "linear-gradient(0deg, #273582, #101A50)",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "stretch",
        }}
        ref={ref}
      >
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexFlow: "column nowrap", gap: 10 }}>
            <Button
              size="large"
              variant="contained"
              color="success"
              onClick={() =>
                updateLiveScorer({
                  input: { token, team1Score: data.liveScorer.team1Score + 1 },
                })
              }
            >
              +1
            </Button>
            <Button
              size="large"
              variant="contained"
              color="error"
              disabled={!data || !data.liveScorer.team1Score}
              onClick={() =>
                updateLiveScorer({
                  input: { token, team1Score: data.liveScorer.team1Score - 1 },
                })
              }
            >
              -1
            </Button>
          </div>
          <Button size="large" variant="contained" onClick={() => periodSelectingSet(true)} color="secondary">
            {data?.liveScorer.period}
          </Button>
          <Modal open={periodSelecting} onClose={() => periodSelectingSet(false)}>
            <Paper
              style={{
                display: "flex",
                flexFlow: "row wrap",
                width: "max(50vh, 50vw)",
                padding: 10,
                gap: 10,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {PERIODS.map((period) => (
                <Button
                  variant="outlined"
                  {...(period === data?.liveScorer.period && { color: "secondary", variant: "contained" })}
                  key={period}
                  size="large"
                  onClick={() => {
                    updateLiveScorer({
                      input: { token, period },
                    });
                    periodSelectingSet(false);
                  }}
                >
                  {period || "??"}
                </Button>
              ))}
            </Paper>
          </Modal>
          <div style={{ display: "flex", flexFlow: "column nowrap", gap: 10 }}>
            <Button
              size="large"
              color="success"
              variant="contained"
              disabled={!data}
              onClick={() =>
                updateLiveScorer({
                  input: { token, team2Score: data.liveScorer.team2Score + 1 },
                })
              }
            >
              +1
            </Button>
            <Button
              size="large"
              variant="contained"
              color="error"
              disabled={!data || !data.liveScorer.team2Score}
              onClick={() =>
                updateLiveScorer({
                  input: { token, team2Score: data.liveScorer.team2Score - 1 },
                })
              }
            >
              -1
            </Button>
          </div>
        </div>
        {data && <img src={data.liveScorer.overlaySvgUrl} />}
      </div>
    </HorizontalView>
  );
}
