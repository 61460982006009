import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const ReadminCsvExportDeleteDialog = makeConfirmActionDialog({
  mutationName: "readminCsvExportDelete",
  fragment: "currentUser { id readminCsvExports { id } }",
  action: "Delete CSV Export",
  paramName: "readminCsvExportId",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Delete",
    };
  },
});
