import { gql } from "@apollo/client";
import DataTable from "controls/DataTable";
import ReadminLink, { ReadminLinkFragment } from "controls/ReadminLink";
import { VideoThumbnailLoad } from "controls/VideoThumbnail";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import React from "react";

export default function AdPresetCourtPreviewTab({ adPresetId }) {
  const [data, dataMeta] = useData(
    gql`
      query AdPresetCourtPreviewTab($adPresetId: ID!) {
        adPreset(id: $adPresetId) {
          id
          club {
            id
            courts {
              id
              advertisingActive
              ...ReadminLinkFragment
            }
          }
        }
      }
      ${ReadminLinkFragment}
    `,
    { adPresetId },
  );
  const adPresetPreviewMotioncrop = useActionFragment("adPresetPreviewMotioncrop", "jpgImageBase64");

  return (
    <>
      <DataTable
        defaultMode="grid"
        columns={["Court"]}
        rows={data?.adPreset.club.courts.map((court) => ({
          key: court.id,
          thumbnail: (
            <VideoThumbnailLoad
              loadCallback={
                !dataMeta.loading &&
                data &&
                (async () => {
                  if (!court.advertisingActive) throw new Error("Advertising is disabled on this court.");

                  const result = await adPresetPreviewMotioncrop({
                    input: {
                      adPresetId,
                      courtId: court.id,
                    },
                  });

                  const jpgImageBase64 = result.adPresetPreviewMotioncrop.jpgImageBase64;

                  if (!jpgImageBase64) throw new Error("No video available for preview.");

                  return { base64: jpgImageBase64, type: "image/jpeg" };
                })
              }
            />
          ),
          cells: [<ReadminLink to={court} />],
        }))}
      />
    </>
  );
}
