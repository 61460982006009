import DialogsToolbar from "controls/DialogsToolbar";
import React from "react";

import ReadminPage from "../ReadminPage";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ReadminDashboardCsvExports from "./CsvExports";
import ReadminDashboardSearch from "./DashboardSearch";
import ReadminDashboardRoles from "./Roles";

export default function ReadminDashboard() {
  return (
    <ReadminPage
      //
      title="Glory League Admin"
      toolbar={<DialogsToolbar dialogs={[<ChangePasswordDialog />]} />}
    >
      <ReadminDashboardSearch />
      <ReadminDashboardCsvExports />
      <ReadminDashboardRoles />
    </ReadminPage>
  );
}
