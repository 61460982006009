import { gql } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import ImageCanvas from "controls/ImageCanvas";
import useLoadedImage from "helpers/useLoadedImage";
import useData from "hooks/useData";
import React from "react";

export default function CameraLiveImageTab({ cameraId }) {
  const [data] = useData(
    gql`
      query LiveImageTab($cameraId: ID!) {
        camera(id: $cameraId) {
          id
          liveImageUrl
        }
      }
    `,
    { cameraId },
  );
  const [liveImage, liveImageLoading] = useLoadedImage(data?.camera.liveImageUrl);

  return (
    <>
      {liveImageLoading && <LinearProgress />}
      {liveImage && <ImageCanvas image={liveImage} style={{ width: "100%" }} />}
    </>
  );
}
