import { gql } from "@apollo/client";
import Message from "controls/Message";
import React from "react";

export const ReadminCsvExportMessageFragment = gql`
  fragment ReadminCsvExportMessageFragment on ReadminCsvExport {
    id
    title
    state
  }
`;

export default function ReadminCsvExportMessage({ readminCsvExport, ...others }) {
  return (
    <Message
      {...others}
      title={
        <>
          CSV Export: <b>{readminCsvExport?.title}</b>
        </>
      }
      type={
        {
          completed: "success",
          failed: "error",
        }[readminCsvExport?.state] || "info"
      }
      content={
        <>
          {readminCsvExport?.state === "pending" && <p>This export has not yet started.</p>}
          {readminCsvExport?.state === "exporting" && (
            <p>This export is currently being generated. You will receive an email when it is ready.</p>
          )}
          {readminCsvExport?.state === "completed" && <p>This export is ready for download.</p>}
          {readminCsvExport?.state === "failed" && <p>This export failed to generate.</p>}
        </>
      }
    />
  );
}
