import { createTheme } from "@mui/material";

export const COLOR_PRIMARY = "#1F2F74";
export const COLOR_SECONDARY = "#F1394B";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default muiTheme;
