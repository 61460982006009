import { gql } from "@apollo/client";
import Message from "controls/Message";
import Tabs from "controls/Tabs";
import useData from "hooks/useData";
import React, { cloneElement } from "react";
import { useParams } from "react-router-dom";
import ReadminPage from "readmin_pages/ReadminPage";

import ReadminAssociationTableTab from "./ReadminAssociationTableTab";
import ReadminDetailsTab from "./ReadminDetailsTab";
import ReadminObjectDialogToolbar from "./ReadminObjectDialogToolbar";
import ReadminObjectLabelDescriptions, {
  ReadminObjectLabelDescriptionsFragment,
} from "./ReadminObjectLabelDescriptions";
import ReadminObjectLabels, { ReadminObjectLabelsFragment } from "./ReadminObjectLabels";
import ReadminObjectThumbnail from "./ReadminObjectThumbnail";

export function makeReadminObjectPage(
  typename,
  paramName,
  typeSingular,
  { title = null, tabs = [], prependTabs = [], noDetailsTab = false } = {},
) {
  return function () {
    return <ReadminObjectPage {...{ typename, paramName, typeSingular, title, tabs, prependTabs, noDetailsTab }} />;
  };
}

function ReadminObjectPage({
  title,
  typeSingular,
  typename,
  paramName,
  prependTabs,
  noDetailsTab,
  tabs,
  readminObject,
}) {
  let { clubId, apiPartnerId, [paramName]: id } = useParams();

  const [data, dataMeta] = useData(
    gql`
      query ReadminObjectPage($typename: String!, $id: ID!) {
        readminObject(typename: $typename, id: $id) {
          id
          readminName
          ...ReadminObjectLabelsFragment
          ...ReadminObjectLabelDescriptionsFragment
        }
      }
      ${ReadminObjectLabelDescriptionsFragment}
      ${ReadminObjectLabelsFragment}
    `,
    {
      typename,
      id,
    },
  );

  if (paramName === "apiPartnerId") apiPartnerId = id;
  if (paramName === "clubId") clubId = id;

  return (
    <ReadminPage
      title={title || data?.readminObject?.readminName}
      toolbar={<ReadminObjectDialogToolbar id={id} typename={typename} gotoReadminObjectUrl />}
      subheader={<>{typeSingular}</>}
      labels={
        <ReadminObjectLabels
          readminObject={data?.readminObject}
          onSubmit={async () => {
            if (data) await dataMeta.refetch();
          }}
        />
      }
      thumbnail={<ReadminObjectThumbnail id={id} typename={typename} />}
      clubId={clubId}
      apiPartnerId={apiPartnerId}
    >
      {data && !data.readminObject ? (
        <Message
          type="error"
          title="Record Not Found"
          content={`Could not find this ${typeSingular} record. It may have been deleted.`}
        />
      ) : (
        <>
          <ReadminObjectLabelDescriptions readminObject={readminObject} />
          <Tabs
            tabs={[
              ...prependTabs.map(([name, title, component]) => [
                name,
                title,
                cloneElement(component, { [paramName]: id, id, typename }),
              ]),
              ...[
                //
                [
                  "details",
                  "Details",
                  <ReadminDetailsTab
                    onSubmit={async () => {
                      if (data) await dataMeta.refetch();
                    }}
                  />,
                  { hidden: noDetailsTab },
                ],
                ...tabs,
                ["notes", "System Notes", <ReadminAssociationTableTab associationName="notes" />],
              ].map(([name, title, component, options]) => [
                name,
                title,
                cloneElement(component, { [paramName]: id, id, typename }),
                options,
              ]),
            ]}
          />
        </>
      )}
    </ReadminPage>
  );
}
