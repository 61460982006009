import { gql } from "@apollo/client";
import DialogsToolbar from "controls/DialogsToolbar";
import useData from "hooks/useData";
import React from "react";
import ReadminCsvExportMessage, {
  ReadminCsvExportMessageFragment,
} from "readmin_pages/csv_exports/ReadminCsvExportMessage";

import ViewReadminCsvExportDialog from "../csv_exports/ViewReadminCsvExportDialog";

export default function ReadminDashboardCsvExports() {
  const [data, dataMeta] = useData(gql`
    query ReadminDashboardCsvExports {
      currentUser {
        id
        readminCsvExports {
          id
          ...ReadminCsvExportMessageFragment
        }
      }
    }
    ${ReadminCsvExportMessageFragment}
  `);
  return (
    <>
      {data?.currentUser?.readminCsvExports.map((csvExport) => (
        <ReadminCsvExportMessage
          key={csvExport.id}
          readminCsvExport={csvExport}
          toolbar={
            <DialogsToolbar
              onSubmit={async () => {
                await dataMeta.refetch();
              }}
              dialogs={[<ViewReadminCsvExportDialog readminCsvExportId={csvExport.id} />]}
            />
          }
        />
      ))}
    </>
  );
}
